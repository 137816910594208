import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as requestFromServer from "../../app/modules/Message/MessageCrud/MessageCrud"
// import { useSelector } from "react-redux";

const Device = require("@twilio/voice-sdk").Device;
// const userData = useSelector((state) => state.auth);

const initialState = {
  callDevice: null,
};

export const callDeviceSlice = createSlice({
  name: "callDevice",
  initialState: {
    callDevice: null,
  },
  reducers: {
    initializeCallDevice: (state) => {
      const loadDevice = async () => {
        try {
          // Fetch the Twilio token from the backend
          const response = await await requestFromServer.getTwilioToken();
          if (!response.token) {
            throw new Error('Failed to fetch Twilio token');
          }

          const token = response.token;

          const device = new Device(token, {
            debug: true,
            answerOnBridge: true,
            codecPreferences: ["opus", "pcmu"],
          });

          state.callDevice = device;
        } catch (err) {
          console.error("Error initializing device:", err);
        }
      };

      loadDevice();
    },

    setDeviceAudio: async (state) => {
      const tempDevice = state.callDevice;

      tempDevice.audio
        .setAudioConstraints({ echoCancellation: true })
        .then(() => {
          tempDevice.audio
            .setInputDevice("default")
            .then(() => {
              // Device audio set successfully
            })
            .catch((err) => {
              console.error("Error setting input device:", err);
            });
        })
        .catch((err) => console.error("Error setting audio constraints:", err));
      state.callDevice = tempDevice;
    },

    unsetDeviceAudio: async (state) => {
      const tempDevice = state.callDevice;
      tempDevice.audio.unsetInputDevice().then(() => {
        // Device audio unset successfully
      });
      state.callDevice = tempDevice;
    },
  },
});

export const setDeviceAudioAsync = createAsyncThunk(
  "callDevice/setDeviceAudio"
);

export const {
  initializeCallDevice,
  setDeviceAudio,
  unsetDeviceAudio,
} = callDeviceSlice.actions;

export default callDeviceSlice.reducer;
