import React, { useState, useEffect, useMemo, useRef } from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ShepherdTour } from "react-shepherd";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import DashboardTour from "../../../../_partials/dashboards/RevenuJobStatus/DashboardTour";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

function triggerMouseEvent(eventType, element) {
  const event = new MouseEvent(eventType, {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
}

const options = {
  "/dashboard": [
    // {
    //   text: "Click on 'New Job' to begin.",
    //   title: "New Job",
    //   attachTo: { element: "#quick_select_job", on: "bottom" },
    //   buttons: [
    //     {
    //       classes: "btn btn-light",
    //       text: "Back",
    //       type: "back",
    //     },
    //     {
    //       classes: "btn btn-light-primary",
    //       text: "Next",
    //       action() {
    //         const selector = document.getElementById("quick_new_job");
    //         selector?.click();
    //         return this.next();
    //       },
    //     },
    //   ],
    // },
    // {
    //   text:
    //     "Type in the address. Google will suggest matches; select the correct one.",
    //   title: "Address Entry",
    //   attachTo: { element: ".tour_address", on: "bottom" },
    // },
    // {
    //   text: "You can assign the job to one or multiple workers.",
    //   title: "Worker Assignment",
    //   attachTo: { element: ".tour_worker", on: "bottom" },
    // },
    // {
    //   text:
    //     "Send an SMS to inform the worker about job details. Customize the message template in settings.",
    //   title: "Messaging Workers",
    //   attachTo: { element: ".tour_message_worker", on: "top" },
    // },
    // {
    //   text:
    //     "If you set an appointment, the worker will receive a reminder SMS an hour before the job starts.",
    //   title: "Scheduling Appointments",
    //   attachTo: { element: ".tour_appointment", on: "top" },
    // },
    // {
    //   text:
    //     "Hit 'Save' to keep your job details or cancel if you change your mind.",
    //   title: "Finally",
    //   attachTo: { element: ".tour_save", on: "bottom" },
    //   buttons: [
    //     {
    //       classes: "btn btn-light",
    //       text: "Back",
    //       type: "back",
    //     },
    //     {
    //       classes: "btn btn-light-primary",
    //       text: "Next",
    //       action() {
    //         const selector = document.getElementById("tour_save_job");
    //         selector?.click();
    //         return this.next();
    //       },
    //     },
    //   ],
    // },
    // {
    //   text: "Check your remaining messages. Tap to buy more packages.",
    //   title: "Messages",
    //   attachTo: { element: ".message_button", on: "bottom" },
    // },
    // {
    //   text:
    //     "Get updates about on activity within your account. Adjust your preferences in settings.",
    //   title: "Notification",
    //   attachTo: { element: ".notification_button", on: "bottom" },
    // },
    // {
    //   text: "Record when workers start and finish their shifts.",
    //   title: "Clock In/Out",
    //   attachTo: { element: ".clock_item", on: "bottom" },
    // },

    {
      text:
        "Choose the info you'd like to display. Changes depending on the page you're on.",
      title: "Edit Layout",
      attachTo: { element: ".page_layout", on: "bottom" },
    },

    // {
    //   text: "See how many call minutes you have left. Click to purchase more.",
    //   title: "Calls",
    //   attachTo: { element: ".call_button", on: "bottom" },
    // },
    // {
    //   text: "Click here when you need help, Sendie is always available.",
    //   title: "Help",
    //   attachTo: { element: ".tour_tutorial", on: "left" },
    //   buttons: [
    //     {
    //       classes: "btn btn-light",
    //       text: "Back",
    //       type: "back",
    //     },
    //     {
    //       classes: "btn btn-light-primary",
    //       text: "Next",
    //       action() {
    //         const selector = document.getElementById("kt_quick_user_toggle");
    //         selector?.click();
    //         return this.next();
    //       },
    //     },
    //   ],
    // },
    {
      text: "Tweak and organise your dashboard's layout.",
      title: "Locks",
      attachTo: { element: ".fa-lock", on: "bottom" },
      modalOverlayOpeningPadding: 5,
    },
    // {
    //   text: "Access all the platform settings and adjustments.",
    //   title: "Settings",
    //   attachTo: { element: ".settings_item", on: "left" },
    // },
    // {
    //   text: "Swap between company data views.",
    //   title: "Franchise",
    //   attachTo: { element: ".tour_franchise", on: "left" },
    //   buttons: [
    //     {
    //       classes: "btn btn-light",
    //       text: "Back",
    //       type: "back",
    //     },
    //     {
    //       classes: "btn btn-light-primary",
    //       text: "Next",
    //       action() {
    //         const selector = document.getElementById("kt_quick_user_close");
    //         selector?.click();
    //         return this.next();
    //       },
    //     },
    //   ],
    // },

    // {
    //   text: "Let's explore more!",
    //   title: "Progress",
    //   attachTo: { element: ".totorial_progress", on: "bottom" },
    // },
  ],
  "/jobs": [
    {
      text: "Click on 'New Job' to begin.",
      title: "Starting Up",
      attachTo: { element: ".tour_new_job", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            const selector = document.getElementById("tour_new_job");
            selector?.click();
            return this.next();
          },
        },
      ],
    },
    {
      text:
        "Fill out these fields to provide the necessary details for your job.",
      title: "Job Information",
      attachTo: { element: ".tour_job", on: "bottom" },
    },
    {
      text:
        "Type in the address. Google will suggest matches; select the correct one.",
      title: "Address Entry",
      attachTo: { element: ".tour_address", on: "bottom" },
    },
    {
      text: "You can assign the job to one or multiple workers.",
      title: "Worker Assignment",
      attachTo: { element: ".tour_worker", on: "bottom" },
    },
    {
      text: "You can select a list from the pipeline to assign this job.",
      title: "Pipeline",
      attachTo: { element: ".tour_pipeline", on: "bottom" },
    },
    {
      text:
        "Send an SMS to inform the worker about job details. Customize the message template in settings.",
      title: "Messaging Workers",
      attachTo: { element: ".tour_message_worker", on: "top" },
    },
    {
      text:
        "If you set an appointment, the worker will receive a reminder SMS an hour before the job starts.",
      title: "Scheduling Appointments",
      attachTo: { element: ".tour_appointment", on: "top" },
    },
    {
      text:
        "The task management section allows you to add specific tasks for each job.",
      title: "Task Management",
      attachTo: { element: ".tour_tasks", on: "top" },
    },
    {
      text:
        "There's also an option for you to upload pdf quotes that will be filled in automatically as a job.",
      title: "PDF Upload",
      attachTo: { element: ".tour_pdf", on: "top" },
    },
    {
      text:
        "Hit 'Save' to keep your job details or cancel if you change your mind.",
      title: "Finally",
      attachTo: { element: ".tour_save", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            const selector = document.getElementById("tour_save_job");
            selector?.click();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Tabs",
      text:
      "<b>Active:</b> View current ongoing jobs.<br /><br />" +
      "<b>History:</b> Check past completed jobs.<br /><br />" +
      "<b>Appointment:</b> See upcoming job appointments.",
      attachTo: { element: ".MuiTabs-root", on: "bottom" },
    },
    {
      title: "Filters",
      text: "Use these to easily search for specific jobs.",
      attachTo: { element: ".tour_filters", on: "bottom" },
    },
    //TODO add this back to tour
    {
      title: "Buttons",
      text:
        "Utilise these for job-related tasks like: <br />" +
        "- Editing job details<br />" +
        "- Generating invoices<br />" +
        "- Sending bulk reviews<br />" +
        "- Obtaining customer signatures<br /><br/>" +
        "To use these options, tick the checkbox next to each job you want to select.",
      attachTo: { element: ".tour_buttons", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
  ],
  newInvoice: [
    {
      title: "Customer Details",
      text:
        "At the top, select your customer and indicate if the invoice has been paid.",
      attachTo: { element: ".tour_invoice_customer", on: "bottom" },
    },
    {
      title: "Adding Items",
      text:
        'Click on "Add Items".\nFill in the item name, quantity, and price.\nSpecify if the mentioned price includes GST. (Adjust GST settings if needed.)',
      attachTo: { element: ".tour_items", on: "top" },
    },
    {
      title: "Signature",
      text:
        "On the right, get the customer's signature via email or have them sign on the spot.",
      attachTo: { element: ".tour_signature", on: "left" },
    },
    {
      title: "Job",
      text:
        "On the left, you can close the job, change its status, add parts to the job, specify parts ownership, send or cancel as needed.",
      attachTo: { element: ".tour_job", on: "right" },
    },
    {
      title: "Send Invoice",
      text: "Remember to click 'Send' when done.",
      attachTo: { element: ".tour_send", on: "left" },
    },
  ],
  navigateNewInvoice: [
    {
      text: "Navigate to Jobs List page.",
      // title: "New Invoice",
      attachTo: { element: ".tour_jobs_list", on: "bottom" },
    },
    {
      text:
        "After selecting a job from the list, you can now create the invoice by clicking on this invoice button.",
      title: "Create New Invoice",
      attachTo: { element: ".tour_new_invoice", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
  ],
  "/invoices": [
    {
      title: "Overview",
      text:
        "This page displays all the invoices you've sent, complete with their details.",
      attachTo: { element: ".tour_invoice_overview", on: "top" },
    },
    {
      title: "Navigating to Jobs",
      text:
        "Click on the 'Job ID' in the table to directly access the related job.",
      attachTo: { element: ".tour_job_id", on: "bottom" },
    },
    {
      title: "Resend Invoice",
      text: "Send the invoice again via email or SMS.",
      attachTo: { element: ".tour_resend_invoice", on: "bottom" },
    },
    {
      title: "Export",
      text: "Save a copy of the invoice for your records.",
      attachTo: { element: ".tour_export", on: "bottom" },
    },
    {
      title: "Payment Reminder",
      text: "Notify the customer about pending payments.",
      attachTo: { element: ".tour_reminder", on: "bottom" },
    },
    {
      title: "Delete",
      text: "Remove the invoice.",
      attachTo: { element: ".tour_delete", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
    {
      title: "Sync",
      text:
        "Integrate and update your accounting software with the invoice details.",
      attachTo: { element: ".tour_sync", on: "bottom" },
    },
  ],
  //   The Maps feature lets you visually track both jobs and technicians based on their locations.

  // Filters: Adjust the view to see specific jobs or technicians.

  // Tabs:

  // Jobs Tab: Focuses on displaying the job locations.
  // Tech Tab: Showcases where each technician is.
  // List Interaction: Clicking on an entry, be it a job or technician, from the list will highlight its location on the map.

  "/maps": [
    {
      title: "Overview",
      text:
        "The Maps feature lets you visually track both jobs and technicians based on their locations.",
      attachTo: { element: ".custom-map", on: "top" },
    },
    {
      title: "Filters",
      text: "Adjust the view to see specific jobs or technicians.",
      attachTo: { element: ".map-filters", on: "bottom" },
    },
    {
      title: "Tabs",
      text:
        "Jobs Tab: Focuses on displaying the job locations.\nTech Tab: Showcases where each technician is.",
      attachTo: { element: ".MuiTabs-root", on: "bottom" },
    },
    {
      title: "List Interaction",
      text:
        "Clicking on an entry, be it a job or technician, from the list will highlight its location on the map.",
      attachTo: { element: ".tour_list", on: "bottom" },
    },
  ],
  "/quotes": [
    {
      title: "Table View",
      text: "This displays all the quotes you've sent out.",
      attachTo: { element: ".tour_quotes", on: "top" },
    },
    {
      title: "Edit Quote",
      text: "Edit the details of a quote.",
      attachTo: { element: ".tour_edit_quote", on: "bottom" },
    },
    {
      title: "View Response",
      text: "View responses from your customers.",
      attachTo: { element: ".tour_view_response", on: "bottom" },
    },
    {
      title: "Transfer Job",
      text: "If a quote is accepted, easily convert it into a job.",
      attachTo: { element: ".tour_transfer_job", on: "bottom" },
    },
    {
      title: "New Quote Button",
      text:
        "Creating a new quote is a straightforward process, very similar to generating an invoice.",
      attachTo: { element: ".tour_new_quote", on: "bottom" },
    },
    {
      title: "Web Leads Tab",
      text:
        "For those who've installed our plugins, this tab will show all contact form submissions from your website.",
      attachTo: { element: ".tour_web_leads", on: "bottom" },
    },
  ],
  "/team": [
    {
      title: "Team List",
      text: "This section allows you to manage all your workers in the system.",
      attachTo: { element: ".tour_team_final", on: "top" },
    },
    {
      title: "Creating a New Team Member",
      text: 'Click on "New Team Member" to begin.',
      attachTo: { element: ".tour_new_team", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            const selector = document.getElementById("tour_new_team");
            selector?.click();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Details",
      text:
        "Enter personal and login information.\nChoose their pay structure and set their permissions.",
      attachTo: { element: ".tour_details", on: "top" },
    },
    {
      title: "Colour",
      text:
        "The colour you assign will represent this worker on the dashboard, especially when they have appointments.",
      attachTo: { element: ".tour_colour", on: "top" },
    },
    {
      title: "Stock Tab",
      text:
        "Assign tools or items to a worker.\nWhen a worker uses tools from their stock after closing a job, the system will track the remaining quantity.\nYou'll receive alerts if the stock level gets too low.",
      attachTo: { element: ".tour_stock_tab", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            return this.next();
          },
        },
      ],
    },
    {
      title: "Saving a Team Member",
      text: "Click save to save your new team member's data.",
      attachTo: { element: ".member_save", on: "top" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            const selector = document.getElementById("close_team_button");
            selector?.click();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Edit",
      text: "Edit details of existing team members.",
      attachTo: { element: ".tour_edit_team", on: "bottom" },
    },
    {
      title: "Sync",
      text:
        "Import or sync members from your accounting software, if activated.",
      attachTo: { element: ".tour_sync_team", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },

    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
  ],
  "/automations": [
    {
      title: "Templates Tab",
      text:
        "Displays options for automated SMS reminders, marketing, and follow-ups.\nBy clicking on any option, you can modify details and set up a new automated message.",
      attachTo: { element: ".tour_templates", on: "bottom" },
    },
    {
      title: "My Automations Tab",
      text:
        "Here you can view a list of all the automations you've previously set up.",
      attachTo: { element: ".tour_automations", on: "bottom" },
    },
  ],
  "/clients": [
    {
      title: "Client View",
      text:
        "Access and search through all your clients' information.\nClients are automatically generated when you create a new job for a non-existing customer.",
      attachTo: { element: ".tour_client_view", on: "bottom" },
    },
    {
      title: "New Client",
      text: "Create a new client.",
      attachTo: { element: ".tour_new_client", on: "bottom" },
    },
    {
      title: "Importing Clients",
      text: "Easily import clients using XLSX or CSV files.",
      attachTo: { element: ".tour_import_clients", on: "bottom" },
    },
    {
      title: "Edit",
      text: "Edit client details as needed.",
      attachTo: { element: ".tour_edit_client", on: "bottom" },
    },
    {
      title: "Accounting Software Integration",
      text:
        "If connected, you can seamlessly transfer client data between your system and your accounting software.",
      attachTo: { element: ".tour_accounting", on: "bottom" },
    },
  ],
  "/settings": [
    {
      title: "General Settings",
      text:
        "Manage various settings like terms & conditions for quotes and invoices, tax and credit fees, and banking details displayed on invoices.",
      attachTo: { element: ".General_Settings", on: "top" },
    },
    {
      title: "Users & Roles",
      text:
        "Configure role permissions to control access to different pages and features.\nLink accounting software and set up review links for Facebook or Google. (For Google, provide the business address and use Google Autocomplete.)",
      attachTo: { element: ".Users", on: "top" },
    },
    {
      title: "Job Settings",
      text: "Adjust settings related to jobs.",
      attachTo: { element: ".Job_Settings", on: "top" },
    },
    {
      title: "Templates",
      text:
        "Manage templates for job-related messages sent to your workers, including SMS and email review templates.",
      attachTo: { element: ".Templates", on: "top" },
    },
  ],

  //TODO company profile tour
  "/account": [
    {
      title: "Company Information",
      text:
        "Edit your company's key details, such as name, address, and contact information.",
      attachTo: { element: ".tour_company_info", on: "top" },
    },
    {
      title: "Working Hours",
      text: "Set your working hours for each day of the week.",
      attachTo: { element: ".working_hours", on: "bottom" },
    },
    {
      title: "Recurring Jobs",
      text: "Check this section if your company might create recurring jobs.",
      attachTo: { element: ".tour_recurring_job", on: "bottom" },
    },
    {
      title: "Account Preference",
      text:
        "Adjust your preferences, including country, region, and time zone.",
      attachTo: { element: ".account_preference", on: "top" },
    },
    {
      title: "Save Company Details",
      text: "Remember to click save to save your newly edited details",
      attachTo: { element: ".account_save", on: "top" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
  ],
  //TODO tour for data transfer
  "/settings/reader": [
    {
      title: "Existing Platforms",
      text:
        "Easily import your data from supported platforms. Select from the available options to get started.",
      attachTo: { element: ".tour_platforms", on: "bottom" },
    },
    {
      title: "Unsupported Platforms",
      text:
        "If your platform is not listed, reach out to us for assistance. We're here to help you get set up smoothly.",
      attachTo: { element: ".tour_others", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            toggleSendieOverlay();
            return this.next();
          },
        },
      ],
    },
    {
      title: "Back to Onboarding",
      text: "Now let's go back to onboarding page and go to the next step!",
      attachTo: { element: ".tour_back_onboarding", on: "right" },
      buttons: [],
    },
  ],
  "/onboarding": [
    {
      title: "Welcome",
      text:
        "The onboarding page walks you through the steps setup your company and get familiar with the basic features of Sendatradie.",
      attachTo: { element: ".tour_welcome", on: "bottom" },
    },
    {
      text:
        "Start a job or quote on-the-go from any page.\nClick on 'New Job' to begin.",
      title: "Quick Actions",
      attachTo: { element: ".quick_action", on: "bottom-start" },
      beforeShowPromise: function() {
        return new Promise((resolve) => {
          const selector = document.getElementById("tour_quick_action");
          triggerMouseEvent("mouseover", selector);
          resolve();
        });
      },
      classes: "mt-48",
      modalOverlayOpeningPadding: 133,
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          action() {
            // const selector = document.getElementById("quick_new_job");
            // selector?.click();
            // ShepherdTour.activeTour?.show([1]);
            return this.next();
          },
        },
      ],
    },
    {
      text: "Check your remaining messages. Tap to buy more packages.",
      title: "Messages",
      attachTo: { element: ".message_button", on: "bottom" },
    },
    {
      text:
        "Get updates about on activity within your account. Adjust your preferences in settings.",
      title: "Notification",
      attachTo: { element: ".notification_button", on: "bottom" },
    },
    {
      text: "Record when workers start and finish their shifts.",
      title: "Clock In/Out",
      attachTo: { element: ".clock_item", on: "bottom" },
    },
    {
      text: "Communicate with your workers with the chat feature.",
      title: "Chat",
      attachTo: { element: ".chat", on: "bottom" },
    },
    {
      text:
        "Click on this profile button to access settings and more features.",
      title: "More",
      attachTo: { element: ".tour_user", on: "bottom" },
    },
    {
      text: "See how many call minutes you have left. Click to purchase more.",
      title: "Calls",
      attachTo: { element: ".call_button", on: "bottom" },
    },

    {
      text:
        "Click the info icon to reactivate the tour or a tutorial video for each page. Just in case you need it 😉",
      title: "Help",
      attachTo: { element: ".tour_tutorial", on: "left" },
    },

    {
      title: "Sendie Help",
      text:
        "You can navigiate back to the onboarding page if you need to through the Sendie Help section.",
      attachTo: { element: ".tour_sendie_help", on: "bottom" },
      buttons: [
        {
          classes: "btn btn-light",
          text: "Back",
          type: "back",
        },
        {
          classes: "btn btn-light-primary",
          text: "Next",
          // action() {
          //   const selector = document.getElementById("kt_quick_user_toggle");
          //   selector?.click();
          //   return this.next();
          // },
          action() {
            window.location.href = "/dashboard";
          },
        },
      ],
    },
    // {
    //   text: "Access all the platform settings and adjustments.",
    //   title: "Settings",
    //   attachTo: { element: ".settings_item", on: "left" },
    // },
  ],
  //TODO Pipeline
  "/pipeline": [
    {
      title: "Pipeline Overview",
      text:
        "The pipeline helps you keep track of your jobs by organizing them into customizable lists. Each list can represent any category, project, or grouping of jobs you want to track. You can add jobs to these lists, and the system will display the total amount due and the number of jobs added in each list.",
      attachTo: { element: ".tour_pipeline_overview", on: "bottom" },
    },
    {
      title: "Creating a List",
      text: "You can start creating new lists using this button.",
      attachTo: { element: ".tour_new_list", on: "bottom" },
    },
    {
      title: "Adding Jobs to Lists",
      text:
        "The 'Add Job' button allows you to quickly create a job that will be added to the particular list.",
      attachTo: { element: ".tour_add_job_list", on: "bottom" },
    },
    {
      title: "Track Job Progress",
      text:
        "You can easily track your jobs by looking at the job count and the total amount due for each list. This allows you to monitor the progress of your jobs and keep everything organized.",
      attachTo: { element: ".tour_job_progress", on: "bottom" },
    },
    {
      title: "Managing Lists",
      text:
        "You can edit, or remove lists anytime. This gives you full control over how you organize your jobs.",
      attachTo: { element: ".tour_manage_lists", on: "bottom" },
    },
  ],
  //TODO Calendar
};

const videos = {
  "/account": "https://www.sendatradie.com/wp-content/uploads/account.mp4",
  "/settings/banking":
    "https://www.sendatradie.com/wp-content/uploads/banking-details-1.mp4",
  "/settings/callmasking":
    "https://www.sendatradie.com/wp-content/uploads/call-masking-1.mp4",
  "/clients": "https://www.sendatradie.com/wp-content/uploads/clients.mp4",
  "/settings/invoice":
    "https://www.sendatradie.com/wp-content/uploads/invoice-settings.mp4",
  "/invoices": "https://www.sendatradie.com/wp-content/uploads/invoice.mp4",
  "/settings/jobtype":
    "https://www.sendatradie.com/wp-content/uploads/job-type-settings.mp4",
  "/jobs": "https://www.sendatradie.com/wp-content/uploads/jobs.mp4",
  "/manage/leads": "https://www.sendatradie.com/wp-content/uploads/leads.mp4",
  "/dashboard":
    "https://www.sendatradie.com/wp-content/uploads/dashboard-1.mp4",
  "/maps": "https://www.sendatradie.com/wp-content/uploads/maps.mp4",
  "/settings/numbering":
    "https://www.sendatradie.com/wp-content/uploads/numbering.mp4",
  "/plans": "https://www.sendatradie.com/wp-content/uploads/plans.mp4",
  "/reports": "https://www.sendatradie.com/wp-content/uploads/reports-1.mp4",
  "/settings/plugin":
    "https://www.sendatradie.com/wp-content/uploads/plugin.mp4",
  "/settings/review":
    "https://www.sendatradie.com/wp-content/uploads/review-settings.mp4",
  "/settings/rolePermissions":
    "https://www.sendatradie.com/wp-content/uploads/role-permission-1.mp4",
  "/settings": "https://www.sendatradie.com/wp-content/uploads/settings.mp4",
  "/settings/sms": "https://www.sendatradie.com/wp-content/uploads/sms.mp4",
  "/settings/tax":
    "https://www.sendatradie.com/wp-content/uploads/tax-credit-fee.mp4",
  "/team": "https://www.sendatradie.com/wp-content/uploads/team.mp4",
  "/settings/t_c":
    "https://www.sendatradie.com/wp-content/uploads/terms-conditions.mp4",
  "settings/reader":
    "https://www.sendatradie.com/wp-content/uploads/terms-conditions.mp4",
};

function toggleSendieOverlay() {
  const overlayButton = document.getElementById("tour_open_help");
  if (overlayButton) {
    overlayButton.click();
  }
}

export default function Tutorials(props) {
  const [show, setShow] = useState(false);

  const [startTour, setStartTour] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const source = params.get("source");

  const newSteps = useMemo(() => makeSteps(props.page), [props.page]);

  useEffect(() => {
    console.log("check url", props.page);
  }, [props.page]);

  useEffect(() => {
    if (props.firstView) {
      setStartTour(true);
    }

    if (source === "onboarding" || source === "onboardingInvoice") {
      console.log("trigger tour");
      setStartTour(true);
    }
  }, [props.firstView, source]);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        const response = await axios.get(
          `https://www.api.sendatradie.com/company/getOnboardingProgress`
        );
        const progress = response.data.result;
        console.log("Tour complete? ", progress.tour_complete);
        // const stepsArray = progress.steps_completed.split(",");

        if (props.page === "/onboarding" && progress.tour_complete === 1) {
          console.log("here tour 1")
          setStartTour(true);

          // Post tutorial completion
          await axios.post(
            `https://www.api.sendatradie.com/company/setOnboardingTutorialComplete`
          );
        }
      } catch (error) {
        console.error("Error fetching onboarding progress:", error);
      }
    };

    checkOnboardingStatus(); // Call the async function
  }, [props.page]);

  function makeSteps(page) {
    const split = page?.split("/");
    // console.log(split);
    if (split?.[1] == "Jobs" && split?.[3]?.startsWith("invoice")) {
      page = "newInvoice";
    }
    console.log("what is source? ", source);
    if (source === "onboardingInvoice") {
      console.log("here in new invoice navigate");
      page = "navigateNewInvoice";
    }
    console.log("check options", options[page]);
    // console.log(page);
    return (
      options[page]?.map((opt, index) => {
        return {
          id: index,
          attachTo: opt.attachTo,
          showOn: () => {
            return !!document.querySelector(opt.attachTo.element);
          },
          classes: opt.classes,
          buttons: opt.buttons || [
            {
              classes: "btn btn-light",
              text: "Back",
              type: "back",
            },
            {
              classes: "btn btn-light-primary",
              text: "Next",
              type: "next",
            },
          ],
          highlightClass: "highlight",
          cancelIcon: {
            enabled: true,
          },
          title: opt.title,
          text: [opt.text],
          beforeShowPromise: opt.beforeShowPromise,
          modalOverlayOpeningPadding: opt.modalOverlayOpeningPadding,
        };
      }) || []
    );
  }

  return (
    <div>
      {newSteps?.length || videos?.[props?.page] ? (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="quick-actions-tooltip">Help</Tooltip>}
        >
          <div className="d-flex align-items-center ml-3 tour_tutorial">
            <span
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
              onClick={() => setShow(true)}
            >
              <i className="fas fa-info-circle"></i>
            </span>
          </div>
        </OverlayTrigger>
      ) : (
        ""
      )}
      <Dialog
        onClose={() => setShow(false)}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            overflow: "visible",
            width: 400,
            display: "flex",
            flexDirection: "horizontal",
          }}
          onClose={() => setShow(false)}
        >
          <div className="row align-items-center">
            <img
              className="col p-0"
              style={{
                maxWidth: 100,
                maxHeight: 100,
                display: "flex",
              }}
              src={toAbsoluteUrl("/icons/sendie3.webp")}
            />
            <div className="col align-text-bottom">
              {/* {props.firstView && props.page == "/dashboard"
                ? `G'day ${props?.user?.fullname?.split(" ")?.[0] ||
                    ""}, welcome! Let me show you around the dashboard with a tour or a short video.`
                : "Sendie is here to help you."} */}
              Sendie is here to help you.
            </div>
          </div>
        </DialogTitle>
        <div
          className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
          style={{ width: 400 }}
        >
          <div
            className="row row-paddingless"
            style={{ height: "auto", width: "auto" }}
          >
            {newSteps?.length ? (
              <div
                className={`square ${
                  videos?.[props?.page] ? " col-6" : " col-12"
                }`}
              >
                <a
                  className="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
                  onClick={() => {
                    console.log("here tour 2")
                    setStartTour(true);
                    setShow(false);
                  }}
                >
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Flag.svg"
                      )}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    Tour
                  </span>
                </a>
              </div>
            ) : (
              ""
            )}
            {videos?.[props?.page] ? (
              <div
                className={`square ${newSteps?.length ? " col-6" : " col-12"}`}
              >
                <a
                  className="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
                  onClick={() =>
                    window.open(videos?.[props?.page], "_blank", "noreferrer")
                  }
                >
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Media/Youtube.svg")}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    Video
                  </span>
                </a>
              </div>
            ) : (
              ""
            )}
            {/* <div
              className="col-6 btn btn-light"
              onClick={() => {
                setStartTour(true);
                setShow(false);
              }}
            >
              Tour
            </div>
            <div className="col-6 btn btn-light">Video</div> */}
          </div>
        </div>
        {/* <DialogActions>
            <Button onClick={(e) => this.handleSave(e)} color="primary">
              Save changes
            </Button>
          </DialogActions> */}
      </Dialog>
      <DashboardTour
        page={props.page}
        newSteps={newSteps}
        startTour={startTour}
        setStartTour={setStartTour}
      />
    </div>
  );
}
