import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useSelector } from "react-redux";

const DraggableModalDialog = (props) => (
  <Draggable handle=".modal-header" bounds="body">
    <div
      className={`modal-dialog ${props.className}`}
      {...props}
      style={{
        margin: 0,
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30vw",
        maxWidth: "90vw",
        backgroundColor: "white",
        borderRadius: "6px",
        boxShadow: "0 5px 15px rgba(0,0,0,.5)",
        zIndex: 1050,
      }}
    >
      {props.children}
    </div>
  </Draggable>
);

const SMSModal = ({
  show,
  onHide,
  customerPhone,
  customerEmail,
  customerName,
  id,
  updateDiaryEntry,
  companyId,
  jobStatus,
  appointmentTime,
  moneyDue,
  paymentMethod,
  additionalInfo,
  companyComment,
  tradieComment,
  address,
}) => {
  const userData = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [text, setText] = useState("");
  const [companyNumbers, setCompanyNumbers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [companySettings, setCompanySettings] = useState(null);

  useEffect(() => {
    if (customerPhone) {
      const newOption = { value: customerPhone, label: customerPhone };
      setOptions([newOption]);
      setSelectedOption([newOption]);
    }
  }, [customerPhone, show]);

  // Fetch templates on component mount
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          "https://www.api.sendatradie.com/client/getClientSMSTemplates",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          setTemplates(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to load SMS templates");
      } finally {
        setIsLoadingTemplates(false);
      }
    };

    if (show) {
      fetchTemplates();
    }
  }, [show]);

  // Function to replace variables in template with actual values
  const replaceVariables = (content) => {
    const statusMap = {
      1: "Pending",
      2: "On the Way",
      3: "Closed",
      4: "Cancelled",
      5: "Awaiting Payment",
      6: "Call Back",
    };

    const variableMap = {
      "{Customer_Name}": customerName || "",
      "{Customer_Email}": customerEmail || "",
      "{Customer_Phone}": customerPhone || "",
      "{Job_Id}": id || "",
      "{Job_Status}": statusMap[jobStatus] || "Unknown status",
      "{Job_Address}": address || "",
      "{Appointment_Time}": appointmentTime
        ? new Date(appointmentTime).toLocaleString()
        : "Not scheduled",
      "{Money_Due}": moneyDue ? `$${moneyDue}` : "Not set",
      "{Payment_Method}":
        paymentMethod === 1
          ? "Cash"
          : paymentMethod === 2
          ? "Credit Card"
          : "Not set",
      "{Additional_Info}": additionalInfo || "",
      "{Company_Comment}": companyComment || "",
      "{Tradie_Comment}": tradieComment || "",
      "{Current_Date}": new Date().toLocaleDateString(),
      "{Company_Id}": companyId || "",
    };

    let processedContent = content;
    Object.entries(variableMap).forEach(([variable, value]) => {
      processedContent = processedContent.replace(
        new RegExp(variable, "g"),
        value
      );
    });

    return processedContent;
  };

  const handleChange = (selectedOption) => {
    if (
      selectedOption?.some((option) => !/^(\+?61|0)4\d{8}$/.test(option.value))
    ) {
      toast.error("Enter a valid number");
      setSelectedOption(options);
    } else {
      setSelectedOption(selectedOption);
      setOptions(selectedOption);
    }
  };

  // Handle template selection
  const handleTemplateChange = async (selected) => {
    if (!selected) return;

    try {
      const response = await axios.get(
        `https://www.api.sendatradie.com/client/getClientSMSTemplate/${selected.value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const template = response.data.data;
        // Replace variables in the template content
        const processedContent = replaceVariables(template.content);
        setText(processedContent);
      }
    } catch (error) {
      console.error("Error fetching template:", error);
      toast.error("Failed to load template");
    }
  };

  // In your SMSModal component
  const validateNumbers = async () => {
    console.log("Starting validateNumbers function");

    if (options.length === 0) {
      toast.error("At least 1 number required");
      return false;
    }

    const numbers = options.map((item) => item.value);
    console.log("Phone numbers to send to:", numbers);

    try {
      // Get company SMS settings
      const smsSettingsResponse = await axios.get(
        `https://www.api.sendatradie.com/company/getCompanySMSSettings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("SMS Settings Response:", smsSettingsResponse.data);

      // Debug display name checks
      const isUsingDisplayName =
        smsSettingsResponse.data?.sms_display_name &&
        !smsSettingsResponse.data?.sms_display_number;
      console.log(
        "SMS Display Name:",
        smsSettingsResponse.data?.sms_display_name
      );
      console.log(
        "SMS Display Number:",
        smsSettingsResponse.data?.sms_display_number
      );
      console.log("Is Using Display Name:", isUsingDisplayName);

      // Debug message construction
      const replyUrl = `${
        window.location.origin
      }/sms-reply/${companyId}/${encodeURIComponent(numbers[0])}/${id}`;
      console.log("Reply URL constructed:", replyUrl);

      const messageToSend = isUsingDisplayName
        ? `${text}\n\nTo reply: ${
            window.location.origin
          }/sms-reply/${companyId}/${encodeURIComponent(numbers[0])}/${id}` // Add id here
        : text;
      console.log("Final message to send:", messageToSend);

      // Debug request payload
      const payload = {
        sender_id: userData.id,
        message: messageToSend,
        numbers: numbers,
        companyNumber: companyNumbers.length === 0 ? null : companyNumbers[0],
        allowReplies: true,
      };
      console.log("Request payload:", payload);

      const response = await axios.post(
        "https://www.api.sendatradie.com/messages/sendClientMessageJob",
        payload
      );
      console.log("SMS API Response:", response);

      // Add diary entry after successful send
      const diaryEntry = `SMS sent to: ${numbers.join(
        ", "
      )}\nMessage: ${messageToSend}`;
      console.log("Creating diary entry:", diaryEntry);

      await axios.post("https://www.api.sendatradie.com/jobs/jobdiary/add", {
        job_id: id,
        company_id: companyId,
        data: [
          {
            type: "sms",
            content: diaryEntry,
            timestamp: new Date().toISOString(),
            saved: true,
          },
        ],
      });

      updateDiaryEntry("sms", diaryEntry, "System", true);
      return true;
    } catch (error) {
      console.error("Error in validateNumbers:", error);
      console.log("Error details:", {
        message: error.message,
        response: error.response?.data,
        stack: error.stack,
      });
      return false;
    }
  };

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const response = await validateNumbers();
      setIsLoading(false);
      if (response) {
        toast.success("Message sent successfully");
        onHide();
      } else {
        toast.error("Message failed to send");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Message failed to send");
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      container={document.body}
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
      style={{ position: "fixed" }}
    >
      <Modal.Header
        closeButton
        className="modal-header"
        style={{ cursor: "move" }}
      >
        <Modal.Title>Send SMS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Template Selection */}
        <div className="form-group row mb-4">
          <div className="col-lg-2 d-flex align-items-center justify-content-center">
            Template:
          </div>
          <div className="col-lg-10">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isLoadingTemplates}
              isLoading={isLoadingTemplates}
              options={templates.map((template) => ({
                value: template.id,
                label: template.name,
              }))}
              onChange={handleTemplateChange}
              isClearable={true}
              placeholder="Select a template..."
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-2 d-flex align-items-center justify-content-center">
            To:
          </div>
          <div className="col-lg-10">
            <CreatableSelect
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              value={selectedOption}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-lg-2 d-flex align-items-center justify-content-center">
            Message:
          </div>
          <div className="col-lg-10">
            <textarea
              rows="8"
              style={{ width: "100%" }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            {/* Character count */}
            <div
              style={{
                textAlign: "right",
                color: "#666",
                fontSize: "0.9rem",
                marginTop: "0.5rem",
              }}
            >
              Characters: {text.length} | Messages:{" "}
              {Math.ceil(text.length / 160)}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tour_save">
          <button
            type="button"
            className="btn btn-light btn-elevate"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-elevate ml-3"
            onClick={handleSend}
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Send"
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SMSModal;
