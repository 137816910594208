// SettingNotification.js
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";

export default function SettingsAutoClockIn() {
  const [taxRate, setTaxRate] = useState(0);
  const [creditFee, setCreditFee] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [radius, setRadius] = useState(150);
  const [minutesAppointment, setMinutesAppointment] = useState(120);
  const [minutesJob, setMinutesJob] = useState(120);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    requestFromServer
      .fetchAutoClockInSettings()
      .then((res) => {
        if (res.data.settings) {
          const {
            enabled,
            radius,
            minutes_appointment,
            minutes_job,
          } = res.data.settings;
          setEnabled(enabled);
          setRadius(radius);
          setMinutesAppointment(minutes_appointment);
          setMinutesJob(minutes_job);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function saveSettings() {
    // TODO validation
    if (radius < 0) {
      toast.error("Radius must be a positive number.");
      return;
    }

    if (minutesAppointment < 0 || minutesJob < 0) {
      toast.error("Minutes must be a positive number.");
      return;
    }

    try {
      setSaving(true);
      await requestFromServer.saveAutoClockInSettings({
        enabled,
        radius,
        minutes_job: minutesJob,
        minutes_appointment: minutesAppointment,
      });

      toast.success("Saved.");
    } catch (err) {
      toast.error(err.message || err || "An error occurred.");
    } finally {
      setSaving(false);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Auto Clock In"></CardHeader>
            <CardBody>
              <div className="row form-group mt-3 mb-2 align-items-center">
                <h3 className="col-xl-3 col-lg-3 col-12 mb-0">
                  Enable auto clock in:
                </h3>
                <Switch
                  checked={enabled}
                  onChange={(e) => setEnabled(e.target.checked)}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <h3 className="row form-group align-items-center ml-0">
                <span className="mt-3 align-middle">
                  Automatically clock in when:
                </span>
              </h3>

              <h3 className="row form-group align-items-center ml-0">
                <span className="mt-3 align-middle">
                  • A technician is within
                </span>
                <div className="col-lg-2 col-12">
                  <input
                    name="radius"
                    className="form-control"
                    onChange={(e) => setRadius(e?.target?.value)}
                    value={radius}
                    type="number"
                    style={{ fontSize: 22.5, fontWeight: 500 }}
                    placeholder="Radius"
                  />
                </div>
                <span className="mt-3 align-middle">
                  metres of an active job.
                </span>
              </h3>

              <h3 className="row form-group align-items-center ml-0">
                <span className="mt-3 align-middle">
                  • And the current time is within
                </span>
                <div className="col-lg-2 col-12">
                  <input
                    name="radius"
                    className="form-control"
                    onChange={(e) => setMinutesAppointment(e?.target?.value)}
                    value={minutesAppointment}
                    type="number"
                    style={{ fontSize: 22.5, fontWeight: 500 }}
                    placeholder="Radius"
                  />
                </div>
                <span className="mt-3 align-middle">
                  minutes of the scheduled appointment.
                </span>
              </h3>

              <h3 className="row form-group align-items-center ml-0">
                <span className="mt-3 align-middle">
                  • Or, if no appointment is set, the current time is within
                </span>
                <div className="col-lg-2 col-12">
                  <input
                    name="radius"
                    className="form-control"
                    onChange={(e) => setMinutesJob(e?.target?.value)}
                    value={minutesJob}
                    type="number"
                    style={{ fontSize: 22.5, fontWeight: 500 }}
                    placeholder="Radius"
                  />
                </div>
                <span className="mt-3 align-middle">
                  minutes of the job creation time.
                </span>
              </h3>

              <div className="row ml-0">
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={saving}
                  onClick={() => {
                    saveSettings();
                  }}
                >
                  Save
                  {saving && (
                    <span
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
