/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHistory } from "react-router";
import { JobAddDialog } from "../../../../../app/modules/Jobs/pages/Jobs/customer-add-dialog/JobAddDialog";
import axios from "axios";

export function QuickActionsDropdown() {
  const [alertMessage, setAlertMessage] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const bgImage = toAbsoluteUrl("/media/misc/bg-2.jpg");
  const uiService = useHtmlClassService();
  const [show, setShow] = useState(false);
  const [openJobModal, setOpenJobModal] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };

  const handleLogoutRedirect = () => {
    window.location.href = "/logout"; // Redirect to the Logout component
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1); // Decrement remaining time
      } else if (isTimerRunning) {
        // Only refresh if the timer was running
        setAlertMessage(null); // Clear the alert when time is up
        setRemainingTime(0); // Reset remaining time
        setIsTimerRunning(false); // Stop the timer
        clearInterval(timer); // Stop the timer
        handleLogoutRedirect(); // Refresh the page
      }
    }, 1000);

    return () => clearInterval(timer); // Clean up on component unmount
  }, [remainingTime, isTimerRunning]); // Include isTimerRunning as a dependency

  useEffect(() => {
    const fetchGlobalAlert = async () => {
      try {
        const response = await axios.get(
          "https://www.api.sendatradie.com/jobs/globalAlerts"
        );
        if (response.data && response.data.length > 0) {
          const currentDateTime = new Date();
          const upcomingAlert = response.data.find(
            (alert) => new Date(alert.scheduled_time) > currentDateTime
          );

          // Place the code block here
          if (upcomingAlert) {
            setAlertMessage(upcomingAlert.message);
            const scheduledTime = new Date(upcomingAlert.scheduled_time);
            const timeDifference = scheduledTime - currentDateTime; // Difference in milliseconds
            setRemainingTime(Math.ceil(timeDifference / 1000)); // Set remaining time in seconds
            setIsTimerRunning(true); // Start the timer
          } else {
            setIsTimerRunning(false); // No timer if there's no upcoming alert
          }
        } else {
          setIsTimerRunning(false); // No upcoming alerts, ensure timer is stopped
        }
      } catch (error) {
        console.error("Error fetching global alert:", error);
      }
    };

    fetchGlobalAlert();
  }, []);

  const hideDropdown = (e) => {
    setShow(false);
  };
  const history = useHistory();

  return (
    <>
      <div
        id="kt_header_menu "
        className={`header-menu header-menu-mobile quick_action`}
      >
        <Dropdown
          drop="down"
          show={show}
          onMouseEnter={showDropdown}
          onMouseLeave={hideDropdown}
          // alignleft
        >
          <ul className={`menu-nav h-100 pt-0 pb-0`}>
            <li className={`menu-item menu-item-rel`}>
              <a className="menu-link menu-toggle p-0" id="tour_quick_action">
                <Dropdown.Toggle
                  as={DropdownTopbarItemToggler}
                  id="kt_quick_actions_panel_toggle"
                >
                  {/*begin::1 Level*/}

                  <span className="menu-text" style={{ padding: "0.65rem" }}>
                    Quick Actions
                  </span>
                </Dropdown.Toggle>
              </a>
            </li>
          </ul>

          {alertMessage && (
            <div
              className="alert alert-warning mt-2"
              style={{ marginLeft: "15px" }}
            >
              {alertMessage}{" "}
              {remainingTime > 0 && ( // Check remainingTime is greater than 0
                <span>
                  {" "}
                  (Time remaining: {Math.floor(
                    remainingTime / (24 * 60 * 60)
                  )}d {/* Days */}
                  {Math.floor(
                    (remainingTime % (24 * 60 * 60)) / (60 * 60)
                  )}h {/* Hours */}
                  {Math.floor((remainingTime % (60 * 60)) / 60)}m{" "}
                  {/* Minutes */}
                  {remainingTime % 60}s) {/* Seconds */}
                </span>
              )}
            </div>
          )}

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            {/* begin: Head */}
            {/* <div
              className="d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h3 className="text-white font-weight-bold font-size-5 mb-0">
                Quick Actions
              </h3>
            </div> */}
            {/* end: Head */}
            <div className="row row-paddingless">
              <div className="col-6">
                <a
                  // href="#"
                  onClick={() => setOpenJobModal(true)}
                  id="quick_new_job"
                  className="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
                >
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Tools/Hummer.svg")}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    New Job
                  </span>
                </a>
              </div>

              <div className="col-6">
                <a
                  // href="#"
                  onClick={() => history.push("/quotes/newquotes")}
                  className="d-block py-10 px-5 text-center bg-hover-light border-bottom"
                >
                  {" "}
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/File-done.svg"
                      )}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    New Quote
                  </span>
                </a>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <JobAddDialog
        show={openJobModal}
        onHide={() => setOpenJobModal(false)}
        type="quickAction"
        transferJob={false}
      />
    </>
  );
}
