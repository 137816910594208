import axios from "axios";
import qs from "qs";

export const LOGIN_URL = "https://www.api.sendatradie.com/login";
export const SOCIAL_LOGIN_URL = "https://www.api.sendatradie.com/socialLogin";
export const REQUEST_PASSWORD_URL =
  "https://www.api.sendatradie.com/login/forgot-password";

export const ME_URL = "https://www.api.sendatradie.com/login/getUser";

export const CHECK_EMAIL = "https://www.api.sendatradie.com/register/checkEmail";

export const GET_COUNTRY = "https://www.api.sendatradie.com/register/country";

export const GET_ABN = "https://www.api.sendatradie.com/register/abn";

export const REGISTER_COMPANY = "https://www.api.sendatradie.com/register/company";

export const REGISTER_USER = "https://www.api.sendatradie.com/register/user";

export const FRANCHISE_URL = "https://www.api.sendatradie.com/login/selectFranchise";

export function login(email, password, status) {
  //hideconsole.log("loggin in using accesss token");
  return axios.post(LOGIN_URL, {
    email,
    password,
    status,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Cache-Control": "no-store,no-cache,must-revalidate",
      Vary: "Origin",
    },
  });
}
export function social_login(data) {
  return axios.post(SOCIAL_LOGIN_URL, data);
}

// export function checkEmail(email) {
//   var data = qs.stringify({
//     email: email,
//   });
//   // /register/checkEmail
//   return axios.post(
//     "https://www.api.sendatradie.com/register/checkEmail",
//     data
//   );
// }

export function registerCompanyReq(data) {
  return axios.post(REGISTER_COMPANY, data);
}

export function registerUser(
  fullname,
  password,
  email,
  phone,
  datetime,
  address,
  companyID,
  status,
  country,
  refer_code,
  verificationCode
) {
  return axios.post(REGISTER_USER, {
    fullname,
    password,
    email,
    phone,
    datetime,
    address,
    companyID,
    status,
    country,
    refer_code,
    confirmation_code: verificationCode,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function requestABN(q) {
  return await axios.post(GET_ABN, { q });
}

export function getCountry() {
  return axios.get(GET_COUNTRY);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  //hideconsole.log("sehej hi");
  return axios.get(ME_URL);
}
export function checkEmail(email) {
  return axios.post(CHECK_EMAIL, {
    email,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

export function selectFranchise(email) {
  return axios.post(FRANCHISE_URL, {
    email,
  });
}

export function appleExchangeToken(data) {
  return axios.post(`${LOGIN_URL}/appleExchangeToken`, data);
}
//login/forgot-password
