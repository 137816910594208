import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ViewDataModal({ show, id, onHide, data, files }) {
  const baseUrl = "https://platform-295512.appspot.com.storage.googleapis.com/";
  const userData = useSelector((state) => state.auth.user);

  const history = useHistory();

  const handleClose = () => {
    onHide();
  };

  useEffect(() => {
    // Hide console.log for production
  }, [data]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">View Data</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={handleClose}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          {Object.keys(data)
            .filter(
              (key) =>
                key !== "files" &&
                key !== "Customer ID" &&
                data[key] != null &&
                data[key] !== "" // Exclude null, undefined, or empty values
            )
            .map((key, index) => (
              <div className="row" key={index}>
                <label className="col-4">{key}</label>
                <div className="col-8">
                  {key === "address" && typeof data[key] === "object" ? (
                    // Construct a formatted address string
                    `${data[key].street_number || ""} ${data[key].route ||
                      ""}, ${data[key].locality || ""} ${data[key]
                      .administrative_area_level_1 || ""}, ${data[key]
                      .country || ""}`
                      .trim()
                      .replace(/\s+/g, " ")
                  ) : typeof data[key] === "object" ? (
                    // Render object as a JSON string for other keys
                    <pre>{JSON.stringify(data[key], null, 2)}</pre>
                  ) : (
                    data[key]
                  )}
                </div>
              </div>
            ))}

          {files && (
            <div className="form-group">
              <div className="row">
                <label className="col-4">Files</label>
              </div>
              <div className="row">
                {files.split(",").map((item, idx) => (
                  <img
                    key={idx}
                    src={baseUrl + item}
                    alt={`File ${idx + 1}`}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginTop: "2px",
                    }}
                    className="col-12"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-light btn-elevate"
        >
          Close
        </button>
        <button
          type="button"
          onClick={() => history.push("/quotes/newquotes")}
          className="btn btn-light-primary btn-elevate"
        >
          Create Quote
        </button>
      </Modal.Footer>
    </Modal>
  );
}
