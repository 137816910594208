import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { saveSetting } from "../../../../Auth/_redux/settingAction";
import { connect } from "react-redux";
import Axios from "./../../../../../../utils/axios";
import moment from "moment";
import CustomBootstrapTable from "../../../../../../_metronic/_helpers/CustomBootstrapTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomPaginationTable from "../../../../../../_metronic/_helpers/CustomPaginationTable";

export function JobsTable(props) {
  const history = useHistory();
  const location = useLocation();
  const [columns, setColumns] = React.useState([{ text: "", dataField: "" }]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = useState(null);
  const [totalSize, setTotalSize] = useState(0);

  const payment_methods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Credit Card" },
    { id: 3, name: "Transfer" },
  ];

  const badge = (text, value) => {
    const values = [
      "",
      "success",
      "warning",
      "primary",
      "danger",
      "info",
      "danger",
    ];
    return <span className={`badge badge-light-${values[value]}`}>{text}</span>;
  };

  const rawStatus = [
    { value: 0, label: "All" },
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "On the Way",
    },
    {
      value: 3,
      label: "Closed",
    },
    {
      value: 4,
      label: "Cancelled",
    },
    {
      value: 5,
      label: "Awaiting Payment",
    },
    {
      value: 6,
      label: "Call Back",
    },
  ];

  function headerTable(value, headerData) {
    //hideconsole.log("headerData is ", headerData);
    let header = Object.keys(props.settings);
    //hideconsole.log("header is ", header);
    //hideconsole.log(header);
    //hideconsole.log(props.settings);
    //hideconsole.log(props.settings.id);
    if (typeof header != "undefined") {
      let tab = "active";
      if (value == 1) tab = "history";
      else if (value == 2) tab = "appointments";

      return header.map((key, index) => {
        if (props.settings[key][tab]) {
          //hideconsole.log(key);
          return {
            text: key.replace(/_/g, " ").toUpperCase(),
            dataField: key,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (col, row) => {
              if (key.toLowerCase() == "address") {
                col = col?.replaceAll(", ", ",\n");
              } else if (key.toLowerCase() == "appointment_time") {
                col = col?.replaceAll(" ", "\n");
              }
              return key != "Parts" ? (
                <div>
                  {key != "id" ? (
                    <span
                      style={{
                        display: "inline-block",
                        whiteSpace: "pre",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {col}
                    </span>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="quick-actions-tooltip">
                          Copy Details
                        </Tooltip>
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-white"
                        // data-toggle="tooltip"
                        // title="Copy Details"
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            `${row.name}\n${row.call_masking_phone ||
                              row.phone}\n${row.address}\n${
                              row.company_comment
                                ? row.company_comment + "\n"
                                : ""
                            }${
                              row.appointment_time
                                ? row.appointment_time + "\n"
                                : ""
                            }${
                              row.address
                                ? "https://maps.google.com/?q=" +
                                  row.address.replace(/\s/g, "")
                                : ""
                            }`
                          );
                          e.stopPropagation();
                        }}
                      >
                        {col}
                      </button>
                    </OverlayTrigger>
                  )}
                  {key == "id" && row.invoiced ? (
                    <span
                      style={{
                        display: "inline-block",
                        whiteSpace: "pre",
                        textOverflow: "ellipsis",
                        color: "grey",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      Invoiced
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div>
                  {col?.length
                    ? col?.split("-\n")?.map((item, index) => {
                        var lines = item?.split("\n");

                        return (
                          <div
                            key={index}
                            style={{
                              maxWidth: 150,
                              textAlign: "left",
                              marginTop: index != 0 ? 5 : undefined,
                            }}
                            className="badge-light badge"
                          >
                            {lines?.map((line, lineIndex) => (
                              <div
                                key={lineIndex}
                                style={
                                  lineIndex != 0
                                    ? {
                                        fontWeight: 500,
                                        marginTop: 3,
                                        whiteSpace: "nowrap",
                                      }
                                    : { whiteSpace: "nowrap" }
                                }
                              >
                                {line}
                              </div>
                            ))}
                          </div>
                        );
                      })
                    : ""}
                </div>
              );
            },
          };
        }
      });
    }
  }

  useEffect(() => {
    if (history.location.pathname == "/jobs") {
      //hideconsole.log(props.settings);
      makeHeader(props.settings);
    }
  }, [props.settings]);

  // useEffect(() => {
  //   var newData = [...data];

  //   props.filters.status != 0 &&
  //     (newData = newData.filter(
  //       (item) => item.job_status_id == props.filters.status
  //     ));
  //   props.filters.jobType != 0 &&
  //     (newData = newData.filter((item) =>
  //       item.job_type?.split(",")?.includes(props.filters.jobType.toString())
  //     ));
  //   props.filters.worker != 0 &&
  //     (newData = newData.filter((item) =>
  //       item.user_id?.split(",")?.includes(props.filters.worker.toString())
  //     ));
  //   props.filters.search &&
  //     (newData = newData.filter(
  //       (item) =>
  //         item?.id?.toString()?.includes(props.filters.search) ||
  //         ("inv" + item?.invoice_number?.toString())?.includes(
  //           props.filters.search.toLowerCase()
  //         ) ||
  //         item?.address
  //           ?.toString()
  //           ?.toUpperCase()
  //           ?.includes(props.filters.search.toUpperCase()) ||
  //         item?.workers
  //           ?.toString()
  //           ?.toUpperCase()
  //           ?.includes(props.filters.search.toUpperCase()) ||
  //         item?.company_comment
  //           ?.toUpperCase()
  //           ?.includes(props.filters.search.toUpperCase()) ||
  //         item.email
  //           ?.toUpperCase()
  //           ?.includes(props.filters.search.toUpperCase()) ||
  //         item?.phone?.toString()?.includes(props.filters.search) ||
  //         item?.name
  //           ?.toUpperCase()
  //           ?.includes(props.filters.search.toUpperCase()) ||
  //         (item.tradie_comment &&
  //           item?.tradie_comment
  //             ?.toUpperCase()
  //             ?.includes(props.filters.search.toUpperCase()))
  //     ));
  //   setFilteredData(newData);
  // }, [props.filters, data]);

  const makeHeader = (headerData) => {
    let makeHeader = [headerTable(props.value, headerData).filter((e) => e)];
    makeHeader = makeHeader[0];
    if (makeHeader.length) {
      setColumns(makeHeader);
    }
  };

  useEffect(() => {
    //hideconsole.log(location);
    if (location.hash == "#onJobsChange" && props.dates?.start) {
      getData();
      props.setIds([]);
      //hideconsole.log("1 triggered");
      history.push("/jobs");
    }
  }, [location]);

  // useEffect(() => {
  //   if (
  //     props.dates.start &&
  //     props.status &&
  //     location.pathname == "/jobs" &&
  //     !isLoading
  //   ) {
  //     //hideconsole.log("get job data---------");
  //     getData();
  //   }
  // }, [props.dates, props.status, props.useDateClosed]);

  useEffect(() => {
    if (pagination) {
      const controller = new AbortController();
      getData();
      return () => {
        controller.abort();
      };
    }
  }, [pagination]);

  const getData = () => {
    //hideconsole.log("getting jobs--------");
    //hideconsole.log(props.filters);
    setIsLoading(true);
    Axios.call("post", `jobs/getJobs`, null, {
      pagination,
      filters: {
        ...props.filters,
        dates: props.dates,
        value: props.value,
        useDateClosed: props.useDateClosed,
      },
    }).then((res) => {
      if (props.filters.id) {
        props.setFilters({ ...props.filters, id: 0 });
        history.replace({ search: "" });
      }
      setTotalSize(res.data.pagination?.totalSize);
      var newData = res.data.data.map((item) => {
        //hideconsole.log("create_by", item.created_by);
        //hideconsole.log(item);
        return {
          ...item,
          job_status: badge(
            rawStatus[item.job_status]?.label,
            rawStatus[item.job_status]?.value
          ),
          appointment_time:
            item.appointment_time &&
            item.appointment_time != "0000-00-00 00:00:00"
              ? moment(item.appointment_time).format("yyyy-MM-DD HH:mm:ss")
              : "",
          date_created: moment(item.date_created).format("yyyy-MM-DD"),
          date_closed:
            item.date_closed && item.date_closed != "0000-00-00"
              ? moment(item.date_closed).format("yyyy-MM-DD")
              : null,
          amount_due: `$${item.amount_due}`,
          payment_method: item.payment_method
            ? badge(
                payment_methods[item.payment_method - 1]?.name,
                item.payment_method
              )
            : "",
          address: item.address,
          created_by: item.created_by,
        };
      });

      setRawData(newData);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // Ensure unique rows in the raw data
    const uniqueData = _.uniqBy(rawData, "id"); // Filter duplicates by the 'id' field

    setData(
      uniqueData.map((item) => {
        return {
          ...item,
          workers: item.user_id
            ? item.user_id
                .split(",")
                ?.map(
                  (obj) => props.worker?.find((opt) => opt.value == obj)?.label
                )
                ?.join(", ")
            : "",
        };
      })
    );
  }, [props.worker, rawData]);

  return (
    // <CustomBootstrapTable
    //   data={filteredData}
    //   columns={columns}
    //   ids={props.ids}
    //   setIds={props.setIds}
    //   isLoading={isLoading}
    //   isSelectable
    // />
    <CustomPaginationTable
      data={data}
      columns={columns}
      isSelectable
      ids={props.ids}
      setIds={props.setIds}
      isLoading={isLoading}
      setPagination={setPagination}
      totalSize={totalSize}
      resetPage={props.resetPage}
      setResetPage={props.setResetPage}
    />
  );
}

const mapStateToProps = (state) => {
  return state.settings;
};

export default connect(mapStateToProps, { saveSetting })(JobsTable);
