/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import Settings from "./components/Settings";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { selectFranchise } from "../../../../app/modules/Auth/_redux/authCrud";
import { NavDropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import Tutorials from "./components/Tutorials";
import CustomAxios from "../../../../utils/axios";

function SubHeader(props) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const user = useSelector((state) => state.auth.user);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const [number, setNumber] = useState("");
  const [showDialer, setShowDialer] = useState(false);
  const [displayedNumber, setDisplayedNumber] = useState("");
  const handleHideDialer = () => {
    setDisplayedNumber("");
    setShowDialer(false);
  };
  const [franchise, setFranchise] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    ////hideconsole.log("location.pathname")
    ////hideconsole.log(location.pathname)
    ////hideconsole.log("aside.breadcrumbs")
    ////hideconsole.log(aside.breadcrumbs)
    ////hideconsole.log("header.breadcrumbs")
    ////hideconsole.log(header.breadcrumbs)

    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);

    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  const today = new Date();
  const month = today.toLocaleString("default", { month: "short" });
  const day = today.getDate();

  const franchiseSelected = (email) => {
    selectFranchise(email)
      .then(({ data }) => {
        ////hideconsole.log(data);
        if (data.accessToken) {
          props.franchise(data.accessToken);
        }
        localStorage.setItem("expiryToken", data.exp);
        setTimeout(() => {
          // setisLoading(false);
          window.location.reload(false);
        }, 2000);
        // disableLoading();
      })
      .catch((error) => {
        let status = 500;
        let data = error.message || error;

        if (error && error.response && error.response.data) {
          data = error.response.data.data;
          toast.error(data);
        }
        //console.log(error);
      });
  };

  useEffect(() => {
    CustomAxios.call("get", `/company/getFranchises`).then((res) => {
      //hideconsole.log(res.data.franchise);
      //hideconsole.log(user.franchise);
      setFranchise(res.data.franchise);
    });
  }, [user]);


  const pathsNotRequired = [
    "/pipeline",
    "/calendar",
    "/maps",
    "/reports",
    "/insight",
    "/ScheduleUpdateAlert",
    "/account",
    "/franchise",
    "/referfriend",
    "/settings/calendar",
    "settings/notification",
    "/settings/sms",
    "/settings/callmasking",
    "/settings/t_c",
    "/settings/tax",
    "/settings/invoice",
    "/settings/numbering",
    "/settings/plans",
    "/settings/banking",
    "/settings/plugin",
    "/jobsettings/customFields",
    "/settings/reader",
    "/settings/forms",
    "/AddPaymentTerms",
    "/invoicebuilder",
    "/settings/payment",
    "/settings/rolePermissions",
    "/settings/accounting",
    "/settings/review",
    "/shift/allocation",
    "/settings/loginlist",
    "/settings/jobtype",
    "/settings/fieldvalidation/jobs",
    "/jobsettings/reccommendedWorker",
    "/settings/stock",
    "/invoice/reminder",
    "/template/reviewsms",
    "/template/email",
    "/template/client-email",
    "/template/client-SMS",
    "/social/googleMyBusiness",
    "/social/accounts",
    "/customSettings/featurePermissions",
    "/callSettings/incomingCall",
    "/emailTemplate/generator",
    "/registration/emailFlow",
    "/email/marketing",
    "/template/jobmessage",
    "/plans",
    "/message",
    "/plans/sms",
  ];

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* {location.pathname == "/message" ? (
            <div className="d-flex">
              <h5 className="my-auto mr-2">+61</h5>
              <input
                type="tel"
                className="form-control mr-3"
                value={number}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
                onChange={(e) => setNumber(e.target.value)}
              ></input>
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder"
                onClick={() => handleDial()}
              >
                Dial
              </button>
            </div>
          ) : (
            ""
          )} */}

          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>{subheader.title}</>
              {/*<small></small>*/}
            </h5>
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>

        {franchise?.[0] && (
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={user.company_name}
            //menuVariant="dark"
            className="font-weight-bold font-size-h6 text-dark text-hover-primary link-dark"
            style={{
              color: "dark",
            }}
          >
            {franchise.map((item) => {
              if (item.id != user.company_id) {
                return (
                  <NavDropdown.Item
                    style={{ minWidth: "250px" }}
                    key={item.id}
                    onClick={() => franchiseSelected(item.email)}
                  >
                    {item.name}
                  </NavDropdown.Item>
                );
              }
            })}
          </NavDropdown>
        )}

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <a
            href="#"
            className="btn btn-light btn-sm font-weight-bold"
            id="kt_dashboard_daterangepicker"
            data-toggle="tooltip"
            title="Select dashboard daterange"
            data-placement="left"
            style={{ marginRight: "15px" }}
          >
            <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today
            </span>
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {month} {day}
            </span>
          </a>
          {props.page}

          {!pathsNotRequired.includes(location.pathname) ? (
            <Settings
              page={location.pathname}
              settings={props.settings}
              handleSettingChange={props.handleSettingChange}
              rolePermissions={rolePermissions}
            />
          ) : (
            ""
          )}

          <Tutorials
            page={location.pathname}
            settings={props.settings}
            firstView={props.firstView}
            user={user}
            // handleSettingChange={props.handleSettingChange}
            // rolePermissions={rolePermissions}
          />
        </div>
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(SubHeader));
