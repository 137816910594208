import { Accordion, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Select from "react-select";

export function TasksManagementDialog({
  setTasksInfo,
  workerOptions,
  initialTasks,
}) {
  const [newTask, setNewTask] = useState("");
  const [newDueDate, setNewDueDate] = useState("");
  const [newAssignedTo, setNewAssignedTo] = useState("");
  const [tasks, setTasks] = useState([]);

  //if duplicating
  useEffect(() => {
    console.log("TasksManagementDialog received initialTasks:", initialTasks);
    if (initialTasks?.length > 0) {
      setTasks(initialTasks);
    }
  }, [initialTasks]);

  useEffect(() => {
    // console.log("newTasks", tasks);
    setTasksInfo(tasks);
  }, [tasks]);

  const handleAddTask = () => {
    let missingFields = [];

    if (!newTask) {
      missingFields.push("Task name");
    }
    if (!newDueDate) {
      missingFields.push("Due date");
    }
    if (!newAssignedTo) {
      missingFields.push("Assigned worker");
    }

    if (missingFields.length > 0) {
      const fieldList = missingFields.join(", ");
      const errorMessage = `${fieldList} required.`;
      toast.warn(errorMessage);
      return;
    }

    const newTaskObj = {
      task: newTask,
      dueDate: newDueDate,
      assignedTo: newAssignedTo, // Only passing the ID here
      status: 1,
    };

    setTasks([...tasks, newTaskObj]);

    // toast.success(`Task "${newTask}" added successfully!`);

    setNewTask("");
    setNewDueDate("");
    setNewAssignedTo("");
  };

  // Function to Remove a Task
  const removeTask = (index) => {
    const taskToRemove = tasks[index];
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);

    // Info toast with task name
    // toast.info(`Task "${taskToRemove.task}" removed successfully!`);
  };

  return (
    <div className="form-group row mb-3 tour_tasks">
      <div className="col-lg-12">
        <Accordion>
          <Card className="w-100">
            {/* Accordion Toggle for Tasks */}
            <Accordion.Toggle
              className="p-3 d-flex align-items-center front-weight-bold"
              as={Card.Header}
              eventKey="0"
            >
              <label>Task Management</label>
              <Tooltip
                title="Add tasks by providing a description, due date, and assigning a worker. You can also remove tasks from the list if needed."
                placement="top"
                arrow
                className="ml-2"
              >
                <a>
                  <i className="fas fa-info-circle"></i>
                </a>
              </Tooltip>
            </Accordion.Toggle>

            {/* Accordion Body containing Task Form and Task List */}
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {/* Input Fields for Adding a Task */}
                <div
                  className="form-group row align-items-center mb-3"
                  style={{ marginTop: "20px" }}
                >
                  <div className="col-lg-5">
                    <label>Description</label>
                    <textarea
                      name="newTask"
                      className="form-control"
                      rows="2"
                      value={newTask}
                      onChange={(e) => setNewTask(e.target.value)}
                      style={{
                        width: "100%",
                        marginRight: "129px",
                        height: "calc(1.5em + .75rem + 2px)",
                      }} // Matching the date input height
                    />
                  </div>

                  <div className="col-lg-3">
                    <label>Due Date</label>
                    <input
                      type="date"
                      name="newDueDate"
                      className="form-control"
                      value={newDueDate}
                      onChange={(e) => setNewDueDate(e.target.value)}
                      style={{
                        width: "100%",
                        marginRight: "129px",
                      }}
                    />
                  </div>

                  <div className="col-lg-3 tour_worker">
                    <label>Assign Worker</label>

                    <Select
                      name="newAssignedTo"
                      value={
                        newAssignedTo
                          ? workerOptions.find(
                              (option) => option.value === newAssignedTo
                            )
                          : ""
                      }
                      onChange={(selectedOption) =>
                        setNewAssignedTo(
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                      options={workerOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isSearchable
                      placeholder="Select Worker"
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "40px",
                          borderRadius: "4px",
                          borderColor: "#ced4da",
                          cursor: "pointer",
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: "0 8px",
                        }),
                        input: (base) => ({
                          ...base,
                          margin: "0",
                        }),
                      }}
                    />
                  </div>

                  <div className="col-lg-1 d-flex justify-content-start">
                    <button
                      type="button"
                      className="btn btn-light-primary"
                      onClick={handleAddTask}
                      style={{
                        marginTop: "30px",
                        // padding:
                        //   "8px 12px" /* Make padding consistent */,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // paddingRight:
                        //   "5px" /* Adjust paddingRight if needed */,
                        // borderRightWidth: "6px",
                      }}
                    >
                      <i
                        className="fa fa-plus"
                        style={{
                          paddingRight: "0",
                          marginRight: "0",
                          paddingTop: "0",
                        }}
                      />
                    </button>
                  </div>
                </div>

                {/* List of Added Tasks */}
                <div className="task-list" style={{ marginBottom: "20px" }}>
                  {tasks.map((taskItem, index) => (
                    <div
                      className="form-group row align-items-center mb-3"
                      key={index}
                    >
                      <div className="col-lg-5">
                        <textarea
                          className="form-control"
                          value={taskItem.task}
                          disabled
                          style={{
                            width: "100%",
                            height: "calc(1.5em + .75rem + 2px)",
                          }}
                        />
                      </div>

                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          value={taskItem.dueDate}
                          disabled
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            workerOptions.find(
                              (option) => option.value === taskItem.assignedTo
                            )?.label || ""
                          }
                          disabled
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="col-lg-1 d-flex justify-content-start">
                        <button
                          type="button"
                          className="btn btn-light-danger"
                          onClick={() => removeTask(index)}
                          style={{
                            // padding: "8px 12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // paddingRight: "5px",
                          }}
                        >
                          <i
                            className="fa fa-trash"
                            style={{
                              paddingRight: "0",
                              marginRight: "0",
                              paddingTop: "0",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}
