import axios from "axios";

export const MESSAGE_URL = "https://www.api.sendatradie.com/messages";

export function getUsersDataForChat() {
  return axios.get(`${MESSAGE_URL}/getUsers`);
}

export function getMessageChat(data) {
  return axios.post(`${MESSAGE_URL}/getMessage`, data);
}

export function sendMessageChat(data) {
  //hideconsole.log("sendMessage Func data", data);
  return axios.post(`${MESSAGE_URL}/sendMessage`, data);
}

export function getTwilioToken() {
  return axios.get(`${MESSAGE_URL}/twilioToken`)
}