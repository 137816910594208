import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import Select from "react-select";

const DraggableModalDialog = (props) => {
  return (
    <Draggable handle=".modal-header" bounds="body">
      <div
        className={`modal-dialog ${props.className}`}
        {...props}
        style={{
          margin: 0,
          position: "fixed",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30vw",
          maxWidth: "90vw",
        }}
      >
        {props.children}
      </div>
    </Draggable>
  );
};

const EmailModal = ({
  show,
  onHide,
  customerEmail,
  customerName,
  customerPhone,
  id,
  updateDiaryEntry,
  companyId,
  appointmentTime,
  jobStatus,
  address,
  additionalInfo,
  money,
  payBy,
  companyComment,
  tradieComment,
}) => {
  const [emailData, setEmailData] = useState({
    to: customerEmail || "",
    subject: "",
    message: "",
  });
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);

  // Fetch templates on component mount
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          "https://www.api.sendatradie.com/client/getClientEmailTemplates",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.success) {
          setTemplates(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to load email templates");
      } finally {
        setIsLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    setEmailData((prev) => ({
      ...prev,
      to: customerEmail || "",
    }));
  }, [customerEmail]);

  // Function to replace variables in template with actual values
  const replaceVariables = (content) => {
    // Map status IDs to their names
    const statusMap = {
      1: "Pending",
      2: "On the Way",
      3: "Closed",
      4: "Cancelled",
      5: "Awaiting Payment",
      6: "Call Back",
    };

    const variableMap = {
      "{Customer_Name}": customerName || "",
      "{Customer_Email}": customerEmail || "",
      "{Customer_Phone}": customerPhone || "",
      "{Job_Id}": id || "",
      "{Appointment_Time}": appointmentTime
        ? new Date(appointmentTime).toLocaleString()
        : "Not scheduled",
      "{Job_Status}": statusMap[jobStatus] || "Unknown status",
      "{Job_Address}": address || "",
      "{Additional_Info}": additionalInfo || "",
      "{Money_Due}": money ? `$${money}` : "Not set",
      "{Payment_Method}":
        payBy === 1 ? "Cash" : payBy === 2 ? "Credit Card" : "Not set",
      "{Current_Date}": new Date().toLocaleDateString(),
      "{Company_Comment}": companyComment || "",
      "{Tradie_Comment}": tradieComment || "",
      "{Company_Id}": companyId || "",
    };

    let processedContent = content;
    Object.entries(variableMap).forEach(([variable, value]) => {
      processedContent = processedContent.replace(
        new RegExp(variable, "g"),
        value
      );
    });

    return processedContent;
  };

  // Handle template selection
  const handleTemplateChange = async (e) => {
    const templateId = e.target.value;
    if (!templateId) return;

    try {
      const response = await axios.get(
        `https://www.api.sendatradie.com/client/getClientEmailTemplate/${templateId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const template = response.data.data;
        // Replace variables in the template content
        const processedContent = replaceVariables(template.content);

        setEmailData((prev) => ({
          ...prev,
          subject: template.subject || "",
          message: processedContent,
        }));
      }
    } catch (error) {
      console.error("Error fetching template:", error);
      toast.error("Failed to load template");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://www.api.sendatradie.com/jobs/sendClientEmail",
        {
          emailData,
          jobId: id,
        }
      );

      if (response.data.success) {
        const diaryEntry = `Email sent to: ${emailData.to}\nSubject: ${emailData.subject}\nMessage: ${emailData.message}`;

        await axios.post("https://www.api.sendatradie.com/jobs/jobdiary/add", {
          job_id: id,
          company_id: companyId,
          data: [
            {
              type: "email",
              content: diaryEntry,
              timestamp: new Date().toISOString(),
              saved: true,
            },
          ],
        });

        if (updateDiaryEntry) {
          updateDiaryEntry("email", diaryEntry, "System", true);
        }

        toast.success("Email sent successfully");
        onHide();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to send email");
      console.error("Error sending email:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      container={document.body}
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
      dialogClassName="modal-md"
      style={{ position: "fixed" }}
    >
      <Modal.Header
        closeButton
        className="modal-header"
        style={{
          cursor: "move",
          background: "#f8f9fa",
          borderBottom: "1px solid #dee2e6",
        }}
      >
        <Modal.Title>Send Email to Client</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "white" }}>
        <form onSubmit={handleSubmit}>
          {/* Template Selection */}
          <div className="form-group mb-3">
            <label>Email Template:</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={isLoadingTemplates}
              isLoading={isLoadingTemplates}
              name="template"
              options={templates.map((template) => ({
                value: template.id,
                label: template.name,
              }))}
              placeholder="Select a template..."
              onChange={(selected) => {
                if (selected) {
                  // Simulate the event object expected by handleTemplateChange
                  handleTemplateChange({ target: { value: selected.value } });
                }
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>

          <div className="form-group">
            <label>To:</label>
            <input
              type="email"
              name="to"
              value={emailData.to}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Subject:</label>
            <input
              type="text"
              name="subject"
              value={emailData.subject}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Message:</label>
            <textarea
              name="message"
              value={emailData.message}
              onChange={handleChange}
              rows={8}
              className="form-control"
              required
            />
          </div>

          <div className="mt-4 d-flex justify-content-end">
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light me-2"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EmailModal;
