/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./Routes.js";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchRecurrentJobToggleValue } from "./modules/Auth/_redux/settingAction.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { DeviceProvider } from "./modules/SoftPhone/DeviceContext.js";
import { SocketProvider } from "./contexts/SocketProvider.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    //hideconsole.log("facebook sdk initialized");

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    var fb_interval = setInterval(() => {
      if (window.FB) {
        window.FB.init({
          appId: "969871898241770",
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });

        window.FB.AppEvents.logPageView();

        clearInterval(fb_interval);
      }
    }, 100);
  }, []);

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CALENDAR_CLIENT_ID}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              <MaterialThemeProvider>
                <I18nProvider>
                  <DeviceProvider>
                    <Routes />
                    <ToastContainer theme="light" draggable={true} />
                  </DeviceProvider>
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  );
}
