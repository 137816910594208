import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { useState } from "react";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";
import "moment-timezone";

export function EditModal({
  showEditModal,
  setShowEditModal,
  rowData,
  id,
  editedFlag,
  setEditedFlag,
  recurringEndValue,
  setRecurringEndValue,
  endNumber,
  setEndNumber,
  repeatEvery,
  setRepeatEvery,
  repeatOptions,
  selectedRepeatOption,
  setSelectedRepeatOption,
  endDateTime,
  setEndDateTime,
}) {
  const userData = useSelector((state) => state.auth.user);

  const [submitting, isSubmitting] = useState(false);

  const handleHide = () => {
    setShowEditModal(false);
  };
  const handleSubmit = () => {
    //hideconsole.log("Selected end_option", recurringEndValue);
    //hideconsole.log("end_datetime value is", endDateTime);
    console.log("check repeat option", selectedRepeatOption.value)
    isSubmitting(true);
    const editedReccurringData = {
      id: id,
      repeat_every: repeatEvery,
      repeat_option: Number(selectedRepeatOption.value),
      end_option: recurringEndValue,
      end_datetime: endDateTime
        ? moment
            .utc(moment.tz(endDateTime, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss")
        : null,
      end_after_occurances: endNumber,
    };

    
    console.log(editedReccurringData);

    axios
      .post(
        "https://www.api.sendatradie.com/jobs/editReccurringJob",
        editedReccurringData
      )
      .then((res) => {
        // submitting(false);
        toast.success("Saved.");
        setEditedFlag(!editedFlag);
        handleHide();
      })
      .catch((err) => {
        toast.error("Failed to edit job.");
      });

    //hideconsole.log("submitting...");
  };
  return (
    <>
      <Formik>
        <Form>
          <Modal
            show={showEditModal}
            onHide={handleHide}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Reccurring Job
              </Modal.Title>
              <button
                type="button"
                className="btn btn-light btn-close mr-0"
                aria-label="Close"
                onClick={handleHide}
              >
                x
              </button>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="form-group row mt-3">
                  <div
                    className="col-lg-2 tour_appointment"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label>Repeat Every</label>
                  </div>
                  <div className="col-lg-4 tour_appointment">
                    <Field
                      type="number"
                      name="repeatEvery"
                      component={Input}
                      placeholder=""
                      label=""
                      value={repeatEvery}
                      onChange={(e) => {
                        setRepeatEvery(Number(e.target.value));
                      }}
                    />
                  </div>
                  <div
                    className="col-lg-6  tour_appointment"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Select
                      options={repeatOptions}
                      value={selectedRepeatOption}
                      onChange={(e) => {
                        setSelectedRepeatOption(e);
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                        container: (base) => ({
                          ...base,
                          width: "140px", // set width as needed
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row ">
                  <div
                    className="col-lg-2 tour_appointment"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <label>Ends</label>
                  </div>
                  <div
                    className="col-lg-12 tour_appointment"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value="1"
                      checked={recurringEndValue == "1"}
                      onChange={(e) => {
                        setEndDateTime(null);
                        setEndNumber(null);
                        setRecurringEndValue(e.target.value);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <span>Never</span>
                  </div>
                  <div
                    className="col-lg-12 tour_appointment"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value="2"
                      checked={recurringEndValue == "2"}
                      onChange={(e) => {
                        setEndNumber(null);
                        setRecurringEndValue(e.target.value);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <span>On</span>
                    <TextField
                      name="endDateTime"
                      id="datetime-local"
                      variant="outlined"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={endDateTime}
                      style={{ marginLeft: "10px" }}
                      onChange={(e) => {
                        //hideconsole.log(e.target.value);
                        setEndDateTime(e.target.value);
                      }}
                      disabled={recurringEndValue != "2"}
                    />
                  </div>
                  <div
                    className="col-lg-12 "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      value="3"
                      checked={recurringEndValue == "3"}
                      onChange={(e) => {
                        setEndDateTime(null);
                        setRecurringEndValue(e.target.value);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <span className="tour_appointment">After</span>
                    <div className="col-lg-4">
                      <Field
                        className="form-control"
                        type="number"
                        name="endNumber"
                        component={Input}
                        onChange={(e) => {
                          //hideconsole.log(e.target.value);
                          setEndNumber(Number(e.target.value));
                        }}
                        value={endNumber}
                        placeholder=""
                        label=""
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                      />
                    </div>
                    <span style={{ marginLeft: "10px" }}>Occurances</span>
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
              >
                Save
                {/* {submitting ? (
                  <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )} */}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
