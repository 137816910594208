import { useContext, useEffect } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "./shephardStyle.scss";
import { useLocation } from "react-router-dom";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: "font-weight-bold",
    // modalOverlayOpeningPadding: 3,
    modalOverlayOpeningRadius: 6,

    scrollTo: {
      behavior: "smooth",
      // block: "center",
    },
    arrow: false,
  },
  useModalOverlay: true,
};

export default function DashboardTour({ newSteps, startTour, setStartTour }) {
  const location = useLocation();

  // Function to trigger hover programmatically
  function triggerMouseEvent(eventType, element) {
    const event = new MouseEvent(eventType, {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    element.dispatchEvent(event);
  }

  function TourInstance({ startTour, setStartTour }) {
    const tour = useContext(ShepherdTourContext);

    useEffect(() => {
      const handleLoad = () => {
        if (tour && startTour) {
          tour.start();
          // if (location.pathname === "/dashboard") {
          //   const selector = document.getElementById("tour_quick_action");
          //   if (selector) {
          //     triggerMouseEvent("mouseover", selector);

          //     const jobSelector = document.getElementById("quick_new_job");
          //     if (jobSelector) {
          //       const handleElementClick = () => {
          //         if (tour.isActive()) {
          //           tour.show([1]); // Show the next step
          //         }
          //       };

          //       // Add click listener
          //       jobSelector.addEventListener("click", handleElementClick);

          //       // Clean up the event listener when the effect is cleaned up
          //       return () => {
          //         jobSelector.removeEventListener("click", handleElementClick);
          //       };
          //     }
          //   }
          // }
          setStartTour(false);
        }
      };

      if (document.readyState === "complete") {
        handleLoad();
      } else {
        window.addEventListener("load", handleLoad);
      }

      // Clean up event listener on unmount
      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }, [tour, startTour, location.pathname]);

    return <></>;
  }
  return (
    <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
      <TourInstance startTour={startTour} setStartTour={setStartTour} />
    </ShepherdTour>
  );
}
