import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

export function ReceiptEditDailog({
  show,
  onHide,
  receiptData,
  onSubmit,
  hideEdit,
  onNewInvoice,
  hideNewInvoice = false, // Add new prop with default value false
}) {
  const [numPages, setNumPages] = useState(null);
  const [data, setData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (receiptData) {
      // Handle both single invoice and multiple invoices cases
      console.log("Receipt Data:", receiptData);
      if (receiptData.invoice) {
        // Single invoice case
        const singleInvoice = receiptData.invoice;
        console.log("Single Invoice:", singleInvoice);
        setData([singleInvoice]);
        setSelectedInvoice({
          value: singleInvoice,
          label: `${
            singleInvoice.status === "invoice" ? "Invoice" : "Receipt"
          } #${singleInvoice.invoice_number || "N/A"}`,
        });
      } else {
        // Multiple invoices case
        const invoicesArr = receiptData?.invoices || [];
        const receiptsArr = receiptData?.receipts || [];
        const combinedData = [...invoicesArr, ...receiptsArr];
        setData(combinedData);

        if (combinedData.length > 0) {
          setSelectedInvoice({
            value: combinedData[0],
            label: `${
              combinedData[0].status === "invoice" ? "Invoice" : "Receipt"
            } #${
              combinedData[0].status === "invoice"
                ? combinedData[0].invoice_number
                : combinedData[0].receipt_number || "N/A"
            }`,
          });
        }
      }
    }
  }, [receiptData]);

  const invoiceOptions = data.map((doc) => ({
    value: doc,
    label: `${doc.status === "invoice" ? "Invoice" : "Receipt"} #${
      doc.status === "invoice"
        ? doc.invoice_number
        : doc.receipt_number || "N/A"
    }`,
  }));

  const handleInvoiceChange = (selectedOption) => {
    setSelectedInvoice(selectedOption);
  };

  const handleNewInvoice = () => {
    onHide();
    onNewInvoice?.();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title>Invoice/Receipt Viewer</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          {!receiptData.invoice && ( // Only show selector for multiple invoices
            <div style={{ width: "240px" }}>
              <Select
                value={selectedInvoice}
                onChange={handleInvoiceChange}
                options={invoiceOptions}
                className="basic-select"
                classNamePrefix="select"
                placeholder="Select Document"
              />
            </div>
          )}
          {!hideNewInvoice && onNewInvoice && (
            <Button
              variant="success"
              className="ms-3"
              onClick={handleNewInvoice}
            >
              New Invoice
            </Button>
          )}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", height: "400px" }}
        >
          {selectedInvoice?.value?.invoice_file && (
            <iframe
              src={
                selectedInvoice.value.invoice_file.startsWith("https")
                  ? selectedInvoice.value.invoice_file +
                    "#toolbar=0&navpanes=0&scrollbar=0"
                  : `https://storage.googleapis.com/platform-295512.appspot.com/${selectedInvoice.value.invoice_file}#toolbar=0&navpanes=0&scrollbar=0`
              }
              width="80%"
              className="responsive"
            ></iframe>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {!hideEdit && (
          <Button
            variant="primary"
            onClick={() => {
              if (!selectedInvoice?.value?.id) {
                console.error("No invoice ID found in selected invoice");
                return;
              }
              onSubmit(selectedInvoice.value.id);
            }}
          >
            Edit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
