import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../../../_metronic/_partials/controls";
import "../../../../../../../_metronic/_assets/sass/layout/_customStyle.scss";
import * as actions from "../../../../_redux/customers/customersActions";
import styles from "./CustomerAddItemDialog.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";

export function CustomerAddItemDialog({
  show,
  onHide,
  saveItem,
  item,
  prefillItem,
  taxRate,
  advancedSettings,
}) {
  const [data, setData] = React.useState({
    quantity: "",
    description: "",
    label: "",
    price: "",
    include_tax: false,
    tax: 0,
  });
  // const [item, setItem] = React.useState([]);
  const [itemValue, setItemValue] = React.useState(false);
  const userData = useSelector((state) => state.auth.user);
  const [isaddNewIemValue, setaddNewIemValue] = React.useState(true);
  const [showList, setShowList] = React.useState(true);
  const [opened, setOpened] = React.useState(true);
  const [is_taxable, setis_taxable] = React.useState(false);

  // dispatch method
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("check data", data)
    console.log("tax rate", taxRate)
  }, [data, taxRate])

  const addNewIemValue = (values) => {
    dispatch(actions.additem(values));
  };

  function myFunction(e) {
    // var item_field = document.getElementById("field")
    var v = document.getElementById("myUL");
    if (!v.classList.contains("select_item")) {
      v.classList.add("select_item");
    }
    if (e.target.value === "") {
      v.classList.remove("select_item");
    }
    if (!data) {
      setData({ label: e.target.value });
    } else {
      setData({ ...data, label: e.target.value });
    }
    setShowList(true);

    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    //hideconsole.log(li);

    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("div")[0];
      //hideconsole.log(a.innerText);
      //hideconsole.log(e.target.value);
      txtValue = a.textContent || a.innerText;
      //hideconsole.log(a.id);
      if (
        txtValue.toUpperCase().indexOf(filter) > -1 ||
        txtValue == "Create New "
      ) {
        setItemValue(false);
        li[i].style.display = "";
      } else {
        setItemValue(true);
        li[i].style.display = "none";
      }
    }
    // var create_new = document.getElementById("create_new");
    // create_new.style.display = "";
  }

  const handleIncludeTax = () => {
    console.log("set checkbox")
    setData((prev) => {
      return { ...prev, include_tax: !prev.include_tax};
    });
  };

  const handleQuantity = (e) => {
    setData({ ...data, quantity: e.target.value });
  };

  const handlePrice = (e) => {
    setData({ ...data, price: e.target.value });
  };

  const handleDescription = (e) => {
    setData({ ...data, description: e.target.value });
  };

  const handleValidation = () => {
    if (!data.label.trim()) {
      toast.error("Item Name is required.");
      return false;
    }
    if (!data.quantity || isNaN(data.quantity) || Number(data.quantity) < 0) {
      toast.error("Quantity is required must be a positive number.");
      return false;
    }
    if (!data.price || isNaN(data.price) || Number(data.price) < 0) {
      toast.error("Price is required and must be a non-negative number.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    saveItem({
      ...data,
      include_tax: data.include_tax? 1 : 0,
      item: data.label,
      item_description: data.description,
      price:
        data.include_tax && taxRate
          ? ((data.price * 100) / (100 + parseFloat(taxRate)))
          : data.price,
      // tax:
      //   data.includeTax && taxRate
      //     ? (data.price - data.price / (1 + parseInt(taxRate) / 100)).toFixed(2)
      //     : 0,
    });

    if (isaddNewIemValue) {
      addNewIemValue({
        ...data,
        item: data.label,
        item_description: data.description,
      });
    }
  };

  return (
    <div>
      {/* <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg"> */}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add New Item
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>

      <div className="timeline timeline-5 mt-0">
        <Modal.Body>
          {/* {customers.map((customer) => ( */}
          <div className="form-group">
            {/* <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-righ pr-3" /> */}

            <div className="main_select">
              <div className="row mb-1">
                <div className="col-lg-12 pl-1 pr-1">
                  <input
                    name="item"
                    id="myInput"
                    type="search"
                    autoComplete="off"
                    className="form-control"
                    onChange={myFunction}
                    value={data?.label}
                    placeholder="Item Name"
                  ></input>
                </div>
              </div>
              <ul className="add_new_item" id="myUL">
                {item &&
                  item?.map((it, i) => {
                    return (
                      <li
                        key={i}
                        onClick={(e) => {
                          e.stopPropagation();

                          document
                            .getElementById("myUL")
                            .classList.remove("select_item");
                          // handleOpen();
                          //hideconsole.log(it);
                          setaddNewIemValue(false);
                          setData(
                            advancedSettings?.Prefill_Item?.active
                              ? { ...it, is_taxable: false }
                              : {
                                  quantity: 1,
                                  description: it.description,
                                  label: it.label,
                                  price: "",
                                  is_taxable: false,
                                  include_tax: 0,
                                  tax: 0,
                                }
                          );
                        }}
                      >
                        <div>{it.label}</div>
                      </li>
                    );
                  })}
                {!opened && showList && (
                  <li
                    onClick={() => {
                      setItemValue(false);
                      setaddNewIemValue();
                      // handleOpen();
                    }}
                  >
                    <div>Create New </div>
                  </li>
                )}
              </ul>
            </div>
            <div id="field display_field">
              <div className="row mb-1">
                <div className="col-lg-6 pl-1 pr-1">
                  <input
                    name="quantity"
                    type="number"
                    className="form-control"
                    // component={Input}
                    placeholder="Quantity"
                    value={data.quantity}
                    onChange={handleQuantity}
                    // withFeedbackLabel={false}
                    min={0}
                  ></input>
                </div>
                <div className="col-lg-6 pl-1 pr-1">
                  <input
                    name="price"
                    type="number"
                    className="form-control"
                    // component={Input}
                    placeholder="Price"
                    value={data.price}
                    onChange={handlePrice}
                    // withFeedbackLabel={false}
                    min={0}
                  ></input>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-lg-12 pl-1 pr-1">
                  <textarea
                    name="item_description"
                    className="form-control"
                    as="textarea"
                    placeholder="Item Description"
                    value={data.description || ""}
                    onChange={handleDescription}
                    // withFeedbackLabel={false}
                  ></textarea>
                </div>
              </div>
              {/* Added checkbox for asking taxable item or not we can change design later for now i used simple checkbox */}
              <div className="row">
                {advancedSettings?.Include_Tax?.active ? (
                  <div className="col-sm-6">
                    <label className="mt-3">Price Includes Tax ?</label>
                    <div>
                      No
                      <Switch
                        // type="switch"
                        // component={Switch}
                        id="checkbox"
                        // style={{ display: "none" }}
                        name="includes_tax"
                        onClick={handleIncludeTax}
                        checked={Boolean(data?.include_tax)}
                        color="primary"
                      />
                      Yes
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* ))} */}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => onHide(false)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              // onHide={onHide}
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </div>
      {/* </Modal> */}
    </div>
  );
}
