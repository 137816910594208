import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify"; // For toast notifications
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import { useHistory } from "react-router-dom";
import { TasksManagementDialog } from "../TasksManagementDialog";

toast.configure(); // Initialize toast notifications

const TasksTab = ({ tasksData, setUpdatedTasks, customerId, companyId, setTasksToTransfer}) => {
  // Mock tasks data with image
  const [tasks, setTasks] = useState(tasksData);
  const [newTasks, setNewTasks] = useState([]);
  // console.log(tasksData);
  const [rawWorkerOptions, setRawWorkerOptions] = useState([]);
  const history = useHistory();

  console.log(customerId, companyId);
  const [isTransferModal, setIsTransferModal] = useState(false);
  const [taskToTransferIndex, setTaskToTransferIndex] = useState(null);

  //TODO remove logs later
  useEffect(() => {
    console.log("check task data", tasksData);
  }, [tasksData]);

  // Whenever we have new tasks we update back to editForm
  useEffect(() => {
    console.log("check from task dialog", newTasks);
    setUpdatedTasks((prevTasks) => [...prevTasks, ...newTasks]);
  }, [newTasks]);

  // get workers data
  useEffect(() => {
    requestFromServer
      .getWorkers()
      .then((response) => {
        if (response.data.result.length) {
          setRawWorkerOptions(response.data.result);
          // console.log("workers", response.data.result);

          let leaveMapTemp = new Map();

          //hideconsole.log(response.data.result);

          //hideconsole.log("worker options", workerOptions);
          //setShow(true);
        } else {
          //setShow(false);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message == "User not authorized."
        ) {
          history.push("/logout");
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
        // //hideconsole.log("error", error.response.data.message);
      });
  }, []);

  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [updatedTask, setUpdatedTask] = useState("");
  const [updatedDueDate, setUpdatedDueDate] = useState("");
  const [updatedAssignedTo, setUpdatedAssignedTo] = useState("");
  const [completedTasks, setCompletedTasks] = useState([]);
  const [transferTasksList, setTransferTasksList] = useState([]);

  // Handle editing a task by index
  const handleEditTask = (index) => {
    const taskToEdit = tasks[index];
    setEditingTaskIndex(index);
    setUpdatedTask(taskToEdit.task);
    setUpdatedDueDate(taskToEdit.dueDate);
    setUpdatedAssignedTo(taskToEdit.assignedTo);
  };

  // Handle saving the edited task
  const handleSaveTask = () => {
    if (!updatedTask || !updatedDueDate || !updatedAssignedTo) {
      toast.error("All fields are required.");
      return;
    }

    const updatedTaskObj = {
      ...tasks[editingTaskIndex],
      task: updatedTask,
      dueDate: updatedDueDate,
      assignedTo: updatedAssignedTo,
    };

    const updatedTasks = tasks.map((task, index) =>
      index === editingTaskIndex ? updatedTaskObj : task
    );
    setTasks(updatedTasks);

    // Reset the form after saving
    setEditingTaskIndex(null);
    setUpdatedTask("");
    setUpdatedDueDate("");
    setUpdatedAssignedTo("");
    // toast.success("Task updated successfully!");

    //callback function to parent component
    setUpdatedTasks(updatedTasks);
  };

  // Handle marking a task as complete/incomplete
  const toggleTaskCompletion = (index) => {
    if (tasks[index].status === 0) {
      // Mark as incomplete
      //TODO handle tasks status change to 1
      const taskToUpdate = { ...tasks[index], status: 1 };
      const updatedTasks = [...tasks];
      updatedTasks[index] = taskToUpdate;
      setTasks(updatedTasks);
      setUpdatedTasks(updatedTasks);
      // toast.info("Task marked as incomplete");
    } else {
      // Mark as complete
      //TODO handle tasks status change to 0
      const taskToUpdate = { ...tasks[index], status: 0 };
      const updatedTasks = [...tasks];
      updatedTasks[index] = taskToUpdate;
      // console.log("check task status first", updatedTasks);
      setTasks(updatedTasks);
      setUpdatedTasks(updatedTasks);
      toast.success("Task marked as complete");
    }
  };

  // Handle deleting a task
  const handleDeleteTask = (index) => {
    // console.log("task index", index);
    const updatedTasks = tasks.filter((_, taskIndex) => taskIndex !== index);
    setTasks(updatedTasks);
    setUpdatedTasks(updatedTasks);
    // console.log("updated tasks after delete", updatedTasks);
    toast.info("Task deleted successfully");
  };

  // Handle multiple image upload for a task
  const handleImageUpload = (index, event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      const newImages = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((imagesData) => {
        const updatedTasks = tasks.map((task, i) => {
          if (i === index) {
            return {
              ...task,
              images: task.images
                ? [...task.images, ...imagesData]
                : imagesData,
            };
          }
          return task;
        });
        setTasks(updatedTasks);
      });
    }
  };

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  // Handle image delete with confirmation modal
  const confirmImageDelete = (taskIndex, imgIndex) => {
    setCurrentTaskIndex(taskIndex);
    setCurrentImageIndex(imgIndex);
    setIsDeleteModal(true);
  };

  const handleRemoveImage = () => {
    const updatedTasks = tasks.map((task, i) => {
      if (i === currentTaskIndex) {
        const updatedImages = task.images.filter(
          (_, imgIndex) => imgIndex !== currentImageIndex
        );
        return { ...task, images: updatedImages };
      }
      return task;
    });
    setTasks(updatedTasks);
    setIsDeleteModal(false);
  };

  const handleTransferButtonClick = (index) => {
    setTaskToTransferIndex(index);
    setIsTransferModal(true);
    console.log("task index", index)
  }

  const handleTaskTransfer = () => {
    console.log("transfer click");
    // close modal
    setIsTransferModal(false);

    if (taskToTransferIndex === null || taskToTransferIndex === undefined) {
      return console.error("no task found...");
    }

    const taskData = tasks[taskToTransferIndex];
    console.log(tasks);
    console.log("check transfer task index:", taskData, taskToTransferIndex);

    const taskTransferData = {
      company_id: companyId,
      customer_id: customerId,
      tasks_info: taskData,
    };

    console.log("task obj", taskTransferData);
    toast.warning("Please click save after editing to transfer task successfully.");

    const updatedTasks = tasks.filter((_, taskIndex) => taskIndex !== taskToTransferIndex);
    setTasks(updatedTasks);
    setUpdatedTasks(updatedTasks);

    setTransferTasksList(prevTasks => [...prevTasks, taskTransferData]);
  };

  useEffect(() => {
    if (transferTasksList.length) {
      setTasksToTransfer(transferTasksList);
    }
  },[transferTasksList])


  return (
    <div style={{ padding: "20px", width: "100%" }}>
      {tasks?.map((taskItem, index) => (
        <div
          key={index}
          className="task-item mb-3"
          style={{
            opacity: taskItem.status === 0 ? 0.5 : 1, // Lower opacity if task is completed
            transition: "all 0.5s ease",
            position: "relative",
          }}
        >
          {index !== 0 && (
            <hr style={{ border: "1px solid #dee2e6", margin: "20px 0" }} />
          )}

          {editingTaskIndex === index ? (
            <div className="card">
              <div className="card-body">
                {/* First line: Due Date and Assign Worker */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Due Date</label>
                    <input
                      type="date"
                      name="dueDate"
                      className="form-control"
                      value={updatedDueDate}
                      onChange={(e) => setUpdatedDueDate(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Assign To</label>
                    <Select
                      name="assignedTo"
                      value={rawWorkerOptions.find(
                        (option) => option.value === updatedAssignedTo
                      )}
                      onChange={(selectedOption) =>
                        setUpdatedAssignedTo(
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                      options={rawWorkerOptions}
                      isSearchable
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "42px",
                          borderRadius: "4px",
                          borderColor: "#ced4da",
                          cursor: "pointer",
                        }),
                      }}
                    />
                  </div>
                </div>

                {/* Second line: Task Description */}
                <div className="row mb-3">
                  <div className="col-12">
                    <label>Description</label>
                    <textarea
                      name="task"
                      className="form-control"
                      value={updatedTask}
                      onChange={(e) => setUpdatedTask(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                {/* Save button */}
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="button"
                      className="btn btn-light-primary"
                      onClick={handleSaveTask}
                      style={{
                        marginBottom: "10px",
                        padding: "8px 12px",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                {/* First line: Due Date and Assign Worker */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Due Date</label>
                    <span className="form-control">{taskItem.dueDate}</span>
                  </div>
                  <div className="col-md-6">
                    <label>Assigned To</label>
                    <span className="form-control">
                      {rawWorkerOptions.find(
                        (option) => option.value === taskItem.assignedTo
                      )?.label || ""}
                    </span>
                  </div>
                </div>

                {/* Second line: Task Description */}
                <div className="row">
                  <div className="col-12">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      value={taskItem.task}
                      disabled
                      style={{
                        width: "100%",
                        position: "relative",
                        textDecoration:
                          taskItem.status === 0 ? "line-through" : "none",
                        color: taskItem.status === 0 ? "#888" : "#000",
                        transition: "all 0.5s ease",
                      }}
                    />
                  </div>
                </div>

                {/* Completion Checkbox and Action Buttons */}
                <div className="row mt-3 align-items-center">
                  {" "}
                  {/* Add align-items-center to vertically center the row */}
                  <div className="col d-flex">
                    {" "}
                    {/* Add d-flex to ensure the checkbox is aligned */}
                    <input
                      type="checkbox"
                      checked={taskItem.status === 0}
                      onChange={() => toggleTaskCompletion(index)}
                      style={{ width: "32px", height: "32px" }}
                    />
                  </div>
                  <div className="col text-right d-flex justify-content-end align-items-center">
                    {" "}
                    {/* Add justify-content-end and align-items-center */}
                    {/* Edit, Delete, Forward, and Upload Image buttons - disabled if task is completed */}
                    <button
                      type="button"
                      className="btn btn-light-primary"
                      onClick={() => handleEditTask(index)}
                      style={{
                        marginRight: "5px",
                        width: "60px",
                        height: "40px",
                        padding: "0",
                        cursor:
                          taskItem.status === 0 ? "not-allowed" : "pointer",
                      }}
                      disabled={taskItem.status === 0} // Disable when task is complete
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-light-danger"
                      onClick={() => handleDeleteTask(index)}
                      style={{
                        marginRight: "5px",
                        width: "60px",
                        height: "40px",
                        padding: "0",
                        cursor:
                          taskItem.status === 0 ? "not-allowed" : "pointer",
                      }}
                      disabled={taskItem.status === 0} // Disable when task is complete
                    >
                      <i
                        className="fa fa-trash"
                        style={{ paddingRight: "0" }}
                      />
                    </button>
                    <label className="btn btn-secondary" style={{ width: '60px', height: '40px', padding: '0', cursor: taskItem.status === 0 ? 'not-allowed' : 'pointer', marginLeft: '5px', marginBottom: "0" }}>
                      <i className="fa fa-upload" style={{ paddingRight: "0", height: "10px" }} />
                      <input type="file" onChange={(e) => handleImageUpload(index, e)} style={{ display: 'none' }} disabled={taskItem.status === 0} multiple />
                    </label>


                    <button
                      type="button"
                      className="btn btn-info"
                      style={{ width: '60px', height: '40px', padding: '0', marginLeft: "5px", cursor: taskItem.status === 0 ? 'not-allowed' : 'pointer' }}
                      disabled={taskItem.status === 0} // Disable when task is complete
                      onClick={() => handleTransferButtonClick(index)}
                    >
                      <i className="fa fa-share" style={{ paddingRight: '0' }} />
                    </button>
                  </div>
                </div>

                {/* Fourth line: Display uploaded images with "X" button to remove */}
                {taskItem.images && taskItem.images.length > 0 && (
                  <div className="row mt-3">
                    {taskItem.images.map((image, imgIndex) => (
                      <div className="col-4 position-relative" key={imgIndex}>
                        <img
                          src={image}
                          alt="Task"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            marginBottom: "20px",
                            marginTop: "20px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            left: "2px",
                          }}
                        >
                          <CancelIcon
                            fontSize="large"
                            onClick={() => confirmImageDelete(index, imgIndex)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}

      <TasksManagementDialog
        setTasksInfo={setNewTasks}
        workerOptions={rawWorkerOptions}
      />

      {isDeleteModal && (
        <Modal show={isDeleteModal} onHide={() => setIsDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Image Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setIsDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-danger" onClick={handleRemoveImage}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {isTransferModal && (
        <Modal show={isTransferModal} onHide={() => setIsTransferModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Transfer Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to transfer this task?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setIsTransferModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleTaskTransfer}>
              Transfer
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default TasksTab;
