import React, { useState, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Switch } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CustomFieldsRenderer } from "../../../../CustomFields/CustomFieldsRenderer";
import { toast } from "react-toastify";

function NotesTab({
  note,
  setNote,
  actionsLoading,
  onHide,
  customerData,
  noteArr,
  setNoteArr,
  setIsEdit,
  isEdit,
  setIdForNote,
  idForNote,
  handleSubmit,
  workerOptions,
  isSubmitting,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
}) {
  const UserAuthData = useSelector((state) => {
    return state.auth.user;
  });

  const userData = useSelector((state) => state.auth.user);

  const handleAddNotes = () => {
    if (!note.trim()) {
      toast.error("Note cannot be empty");
      return;
    }

    const item = {
      note: note,
      date: moment().format(),
      id: UserAuthData.id,
    };

    if (noteArr && noteArr.length) {
      setNoteArr([...noteArr, item]);
    } else {
      setNoteArr([item]);
    }

    setNote("");
    //hideconsole.log("after addition all notes", noteArr);
  };

  const handleEditNotes = () => {
    if (!note.trim()) {
      toast.error("Note cannot be empty");
      return;
    }

    const prvData = [...noteArr];
    prvData[idForNote] = { ...prvData[idForNote], note: note };
    setNoteArr(prvData);
    setNote("");
    setIsEdit(false);
    setIdForNote(null);
    //hideconsole.log("edit notes", prvData, note);
  };

  const handleDeleteNotes = (id) => {
    if (isEdit) {
      toast.error("Cannot delete note while editing");
      return;
    }

    const prvData = [...noteArr];
    prvData.splice(id, 1);
    //hideconsole.log(prvData);
    setNoteArr(prvData);
  };
  // useEffect(() => {
  //   //hideconsole.log("nptearray", noteArr);
  // }, [noteArr]);

  const tabCustomComponents = customFieldsArray?.filter(
    (item) => item.tab_id == 3 && item.active
  );

  //hideconsole.log("Job Tab components are", tabCustomComponents);

  const tabCustomComponentsSection = [];
  let temp = [];
  for (var i = 0; i < tabCustomComponents?.length; i++) {
    //hideconsole.log(tabCustomComponents);
    temp.push(tabCustomComponents[i]);
    if (temp.length == 3) {
      tabCustomComponentsSection.push(temp);
      temp = [];
    }
  }

  if (temp.length < 3) tabCustomComponentsSection.push(temp);

  //hideconsole.log("tabCustomComponentsSection is", tabCustomComponentsSection);

  return (
    <>
      <Modal.Body>
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <div className="row align-items-center">
          <div className="form-group col-lg-8">
            <label htmlFor="note">Notes</label>
            <textarea
              type="text"
              name="note"
              if="note"
              placeholder="Note"
              value={note}
              autoComplete="off"
              onChange={(e) => {
                setNote(e.target.value);
              }}
              className="form-control"
            ></textarea>
          </div>

          <div className="col-lg-4">
            <button
              type="button"
              className="btn btn-light-primary btn-sm"
              variant="contained"
              color="primary"
              onClick={isEdit ? handleEditNotes : handleAddNotes}
            >
              {isEdit ? "Edit Note" : "Add Note"}
            </button>
          </div>
        </div>
        <CustomFieldsRenderer
          tabCustomComponents={tabCustomComponents}
          tabCustomComponentsSection={tabCustomComponentsSection}
          customFieldsArray={customFieldsArray}
          setCustomFieldsArray={setCustomFieldsArray}
          customFieldsData={customFieldsData}
          setCustomFieldsData={setCustomFieldsData}
        />
        {noteArr &&
          noteArr.map((data, index) => (
            <div key={index}>
              <div className="mt-5">
                <div className="my-2">
                  <b>
                    {data.id == 0
                      ? "Customer"
                      : workerOptions?.find((worker) => worker.value == data.id)
                          ?.label || "Inactive User"}
                  </b>
                  {"  "}
                  on {moment(data.date).format("llll")}
                </div>
                <div>{data.note}</div>
                <div className="d-flex my-2">
                  {UserAuthData?.id === data.id ? (
                    <>
                      <div>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-success"
                          onClick={() => {
                            //hideconsole.log("edit");
                            setIdForNote(index);
                            setIsEdit(true);
                            setNote(data.note);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="ml-2">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={(e) => {
                            handleDeleteNotes(index);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
                <Divider />
              </div>
            </div>
          ))}
      </Modal.Body>
    </>
  );
}

export default NotesTab;
