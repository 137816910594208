import { createContext, useState, useEffect } from "react";
import { IncomingCall } from "./IncomingCall/IncomingCall.js";
import { useSelector } from "react-redux";
import * as requestFromServer from "../../modules/Message/MessageCrud/MessageCrud.js"

const Device = require("@twilio/voice-sdk").Device;

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const state = useSelector((state) => state);
  const identity = state?.auth?.user?.company_name + "Admin";
  const [device, setDevice] = useState(null);
  const [incoming, setIncoming] = useState(false);
  const [call, setCall] = useState({ parameters: { From: "" } });

  // //hideconsole.log("device identity is", identity);

  const mountDeviceAudio = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    device?.audio
      .setAudioConstraints({ echoCancellation: true })
      .then(() => {
        device.audio
          .setInputDevice("default")
          .then(() => {
            //hideconsole.log("successfully set device audio");
            //hideconsole.log("input device is", device.audio.inputDevice);
          })
          .catch((err) => {
            //hideconsole.log("Got error while setting device audio", err);
          });
      })
      .catch((err) => console.log("error in setting input", err));
  };

  const unmountDeviceAudio = async () => {
    if (device && device.audio) {
      device.audio.unsetInputDevice().then(() => {
        console.info("Audio device successfully unmounted!");
      });
    }
  };

  const loadDevice = async () => {
    const callDevice = async () => {
      try {
        // Fetch the Twilio token from the backend
        const response = await requestFromServer.getTwilioToken();
        if (!response.ok) throw new Error("Failed to fetch token");
  
        const { token } = await response.json();
  
        const deviceTemp = new Device(token, {
          debug: true,
          answerOnBridge: true,
          codecPreferences: ["opus", "pcmu"], // Use Opus as the preferred codec
        });
  
        return deviceTemp;
      } catch (err) {
        console.log("Error loading device:", err);
      }
    };
  
    const dev = await callDevice();
  
    const handleSuccessfulRegistration = () => {
      console.log("The device is ready to receive incoming calls.");
    };
  
    dev.on("tokenWillExpire", async () => {
      try {
        // Fetch a new token from the backend when the current one expires
        const response = await requestFromServer.getTwilioToken();
        if (!response.ok) throw new Error("Failed to fetch token");
  
        const { token: updatedToken } = await response.json();
        dev.updateToken(updatedToken);
      } catch (err) {
        console.log("Error updating token:", err);
      }
    });
  
    dev.on("error", async (error) => {
      if (error.code === 31005) {
        console.log("Connection error occurred. Trying to reconnect...");
        await loadDevice(); // Reload device if there’s a connection error
      }
    });
  
    dev.register();
    dev.on("registered", handleSuccessfulRegistration);
  
    dev.on("incoming", (call) => {
      if (!incoming) {
        console.log("Incoming Call SID:", call.parameters.CallSid);
        setCall(call);
        setIncoming(true);
      }
    });
  
    setDevice(dev);
  };  

  useEffect(() => {
    if (state?.auth?.user?.group_id == 4) {
      loadDevice();
    }
  }, []);

  return (
    <DeviceContext.Provider
      value={{ device, setDevice, mountDeviceAudio, unmountDeviceAudio }}
    >
      {children}
      {incoming && (
        <IncomingCall
          call={call}
          setCall={setCall}
          incoming={incoming}
          setIncoming={setIncoming}
        />
      )}
    </DeviceContext.Provider>
  );
};
